import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import Loader from "./Loader";
import ReactSelect from "./ReactSelect";
import NoDataFound from "./NoDataFound";
import { t } from "i18next";

const rowPerPageOption = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
  { label: 40, value: 40 },
];

const DataTable = ({
  columns,
  data,
  search,
  tableClassNameContainer = "table-container",
  maxHeight = "max-h-[calc(100vh-120px)]",
  loading,
  page,
  limit,
  openPopover,
  totalPages,
  onSelect,
  handlePageChange,
  handlePerPageChange,
  handler,
  message = "No Data Found",
}) => {
  const [selectedPerPage, setSelectedPerPage] = useState({
    label: limit || 10,
    value: limit || 10,
  });

  console.log(limit, "limit");
  console.log(selectedPerPage, "selectedPerPage");
  // console.log(data, "dataaa");

  // const [currentPage, setCurrentPage] = useState(1);
  // console.log({ currentPage });
  // const [itemsPerPage] = useState(20);
  // const [inputPage, setInputPage] = useState(1);
  // const [debouncedPage, setDebouncedPage] = useState(1);

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const filteredData = useMemo(() => {
    return data?.filter((item) => {
      return columns.some((column) => {
        if (column?.key) {
          const value = getNestedValue(item, column.key);
          return (
            value &&
            value.toString().toLowerCase().includes(search.toLowerCase())
          );
        }
        return columns;
      });
    });
  }, [data, search, columns]);
  // console.log(data);
  // const debouncedSetPage = useMemo(
  //   () =>
  //     debounce((page) => {
  //       setDebouncedPage(page);
  //     }, 300),
  //   []
  // );

  // const handleInputChange = (event) => {
  //   const value = event.target.value;

  //   if (value === "" || Number(value) < 1 || Number(value) > totalPages) {
  //     setInputPage("");
  //   } else if (Number(value) > 0 && Number(value) <= totalPages) {
  //     setInputPage(Number(value));
  //     debouncedSetPage(Number(value));
  //   }
  // };

  // useEffect(() => {
  //   console.log({ debouncedPage });
  //   setCurrentPage(debouncedPage - 1);
  // }, [debouncedPage]);
  const capitalizeFirstLetter = (text) => {
    if (typeof text !== "string") return text; // Check if text is a string
    return text
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  return (
    <div className={`${tableClassNameContainer} flex flex-col ${maxHeight} `}>
      <div
        className={`flex-grow ${!openPopover && "overflow-auto"} rounded-theme`}
      >
        <table className="relative min-w-full bg-white rounded-theme text-sm_18 2xl:text-base">
          <thead className="sticky top-0 z-10 bg-white">
            <tr>
              {columns.map((column, colIndex) => (
                <th
                  key={column.key || `col-${colIndex}`}
                  className="whitespace-nowrap 2xl:whitespace-normal"
                >
                  {t(column.Header)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
            {!loading &&
              filteredData?.map((row, rowIndex) => (
                <tr
                  className={
                    onSelect ? `cursor-pointer hover:backdrop-blur-xl` : ``
                  }
                  key={row?._id ? `row-${row._id}` : `row-${rowIndex}`}
                  onClick={() => {
                    if (onSelect) onSelect(row);
                  }}
                >
                  {columns.map((column, colIndex) => (
                    <td
                      key={`${row?._id ? `row-${row._id}` : `row-${rowIndex}`}-${column.key || `col-${colIndex}`}`}
                      className="whitespace-nowrap 2xl:whitespace-normal"
                    >
                      {t(column.key)
                        ? capitalizeFirstLetter(getNestedValue(row, column.key))
                        : capitalizeFirstLetter(column.render(row))}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        {loading && (
          <div className="flex items-center justify-center w-full min-h-96">
            <Loader />
          </div>
        )}
        {!loading && filteredData?.length === 0 && (
          <div className="flex items-center justify-center w-full min-h-96">
            <NoDataFound message={t(message)} />
          </div>
        )}
      </div>
      {filteredData?.length !== 0 && (
        <div className="flex items-center justify-between flex-shrink-0 p-2 my-1 bg-white rounded-base">
          <div className="flex items-center gap-x-2">
            <ReactSelect
              options={rowPerPageOption}
              value={selectedPerPage}
              menuPlacement={"top"}
              onChange={(data) => {
                setSelectedPerPage(data);
                handlePerPageChange(data.value);
              }}
              classNamePrefix="table-react-select"
            />
          </div>
          {/* <div className="flex items-center gap-x-2">
          <label htmlFor="page-input">Page:</label>
          <Input
            type="number"
            id="page-input"
            value={inputPage}
            onChange={handleInputChange}
            min="1"
            max={totalPages}
            className="p-3"
          />
          <div>
            <p className="text-lg">/ {totalPages}</p>
          </div>
        </div> */}
          <div className="flex items-center gap-x-5">
            <ReactPaginate
              initialPage={page - 1}
              forcePage={page - 1}
              previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
              nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DataTable;
