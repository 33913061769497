import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import AxiosIntegrations from "../../../AxiosIntegrations";
import { integrationState } from "./state";

/* -------------------------------------------------------------------------- */
/*                           get all device request                           */
/* -------------------------------------------------------------------------- */
export const getSystemAndLogRequest = () => async (dispatch) => {
    try {
        dispatch(integrationState({ key: "getSystemLogData.loading", value: true }));
        const response = await AxiosRequest.get(`/user/log/system-logs`);
        const data = await response.data.data;

        console.log(data, "data....................")
        if (data) {
            dispatch(integrationState({ key: "getSystemLogData.data", value: data }));
            dispatch(integrationState({ key: "getSystemLogData.loading", value: false }));
        }
        return response;
    } catch (error) {
        toast.error(error);
        dispatch(integrationState({ key: "getSystemLogData.loading", value: false }));
        dispatch(integrationState({ key: "getSystemLogData.error", value: error }));
    }
};


/* -------------------------------------------------------------------------- */
/*                           update payment type request                           */
/* -------------------------------------------------------------------------- */
export const updatePaymentTypesRequest = ({ defaultOrgLocationId, values }) => async (dispatch) => {
    try {
        dispatch(integrationState({ key: "paymentTypeData.loading", value: true }));
        const response = await AxiosRequest.put(`/user/update-payment-setting?organization_location_id=${defaultOrgLocationId}`, values);
        const data = await response.data.data;

        console.log(data, "data....................")
        if (data) {
            dispatch(integrationState({ key: "paymentTypeData.data", value: data }));
            dispatch(integrationState({ key: "paymentTypeData.loading", value: false }));

            // toast.success(response.data.message);
        }
        return response;
    } catch (error) {
        // toast.error(error);
        dispatch(integrationState({ key: "paymentTypeData.loading", value: false }));
        dispatch(integrationState({ key: "paymentTypeData.error", value: error }));
    }
};


/* -------------------------------------------------------------------------- */
/*                           get printers settings for location               */
/* -------------------------------------------------------------------------- */
export const getLocationPrinterSettings = ({ defaultOrgLocationId }) => async (dispatch) => {
    try {
        const printer_env = process.env.REACT_APP_ENV;
        const response = await AxiosIntegrations.get('/printer/list', { params: { org_location_id: defaultOrgLocationId, printer_env } });
        const printersList = response?.data?.printersList ? response?.data?.printersList : [];
        dispatch(integrationState({ key: "printers.printersList", value: printersList }));
    } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.msg);
    }
};

/* -------------------------------------------------------------------------- */
/*                           add printer                                      */
/* -------------------------------------------------------------------------- */
export const addPrinterToLocation = ({ printer_data, defaultOrgLocationId }) => async (dispatch) => {
    try {
        const {
            printer_name, printer_mac, paper_width = '80', dot_width = 0, printer_brand = 'STAR',
            printer_type = 'RECEIPT', lane_no
        } = printer_data;

        const printer_env = process.env.REACT_APP_ENV;

        const newPrinter = {
            printer_name,
            printer_mac,
            org_location_id: defaultOrgLocationId,
            printer_env,
            paper_width,
            dot_width,
            printer_brand,
            printer_type,
            lane_no
        }

        const response = await AxiosIntegrations.post('/printer/add', newPrinter);
        const { status, msg = '' } = response.data;

        if (status === 200) {
            toast.success(msg);
        } else {
            toast.error(msg);
        }

        dispatch(getLocationPrinterSettings({ defaultOrgLocationId }));
    } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.msg);
    }
};

/* -------------------------------------------------------------------------- */
/*                           update printer                                   */
/* -------------------------------------------------------------------------- */
export const updateLocationPrinter = ({ printer_id, printer_data, defaultOrgLocationId }) => async (dispatch) => {
    try {
        const printer_env = process.env.REACT_APP_ENV;
        const response = await AxiosIntegrations.post('/printer/update', { printer_id, printer_data, printer_env });
        const { status, msg = '' } = response.data;

        if (status === 200) {
            toast.success(msg);
        } else {
            toast.error(msg);
        }

        dispatch(getLocationPrinterSettings({ defaultOrgLocationId }));
    } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.msg);
    }
};

/* -------------------------------------------------------------------------- */
/*                           remove printer                                   */
/* -------------------------------------------------------------------------- */
export const removePrinterFromLocation = ({ printer_id, defaultOrgLocationId }) => async (dispatch) => {
    try {
        const printer_env = process.env.REACT_APP_ENV;
        const response = await AxiosIntegrations.post('/printer/remove', { printer_id, printer_env });
        const { status, msg = '' } = response.data;

        if (status === 200) {
            toast.success(msg);
        } else {
            toast.error(msg);
        }

        dispatch(getLocationPrinterSettings({ defaultOrgLocationId }));
    } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.msg);
    }
};

/* -------------------------------------------------------------------------- */
/*                           empty printer jobs queue                         */
/* -------------------------------------------------------------------------- */
export const emptyPrinterJobsQueue = ({ printer_id, defaultOrgLocationId }) => async (dispatch) => {
    try {
        const printer_env = process.env.REACT_APP_ENV;
        const response = await AxiosIntegrations.post('/printer/empty', { printer_id, printer_env });
        const { status, msg = '' } = response.data;

        if (status === 200) {
            toast.success(msg);
        } else {
            toast.error(msg);
        }

        dispatch(getLocationPrinterSettings({ defaultOrgLocationId }));
    } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.msg);
    }
};

/* -------------------------------------------------------------------------- */
/*                           test printer                                     */
/* -------------------------------------------------------------------------- */
export const testLocationPrinter = ({ printer_id, defaultOrgLocationId, lane_no, receipt_type, location_name, location_address, org_name, footer }) => async (dispatch) => {
    try {
        const printer_env = process.env.REACT_APP_ENV;

        const requestBody = {
            config: {
                org_location_id: defaultOrgLocationId,
                printer_env,
                lane_no,
                receipt_type,
                location_name,
                location_address,
                org_name,
                footer,
            },
            printer_id,
        };

        const response = await AxiosIntegrations.post('/cloudprnt/test', requestBody);

        const { status, msg = '' } = response.data;

        if (status === 200) {
            toast.success(msg);
        } else {
            toast.error(msg);
        }

        dispatch(getLocationPrinterSettings({ defaultOrgLocationId }));
    } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.msg);
    }
};

