import { t } from "i18next";
import ReactSelect from "../../common/ReactSelect";
import { Button, Card, Typography } from "@material-tailwind/react";
import { PrintReceiptIcon } from "../../svgs";
import { useEffect, useState } from "react";
import Input from "../../common/Input";


export const printerBrandOptions = (t) => {
    return ([
        { label: t("Star Micronics"), value: "STAR" },
        { label: t("Epson"), value: "EPSON" },
    ])
}

export const printerTypeOptions = (t) => {
    return ([
        { label: t("Receipt"), value: "RECEIPT" },
        { label: t("Label"), value: "LABEL" },
    ])
}


export const laneNumberOptions =
    [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
    ]



const EditablePrinterCard = ({ printer, index, onChange, onRemove, onTest, onEmpty }) => {
    const { printer_id } = printer;

    const [printer_name, setPrinterName] = useState(printer.printer_name);
    const [printer_mac, setMacAddress] = useState(printer.printer_mac);
    const [printer_brand, setPrinterBrand] = useState("");
    const [lane_no, setLaneNo] = useState("");
    const [printer_type, setPrinterType] = useState("");
    const [print_jobs, setPrintJobs] = useState(printer.print_jobs)
    const [macError, setMacError] = useState('');

    // Function to validate MAC address format
    const validateMacAddress = (mac) => {
        const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
        return macRegex.test(mac);
    };

    // Generic function to set the label and value for ReactSelect
    const setSelectState = (key, value) => {
        const optionsMap = {
            printer_brand: printerBrandOptions(t),
            printer_type: printerTypeOptions(t),
            lane_no: laneNumberOptions
        };

        const selectedOption = optionsMap[key]?.find(option => option.value === value);
        return selectedOption || { label: "", value: "" };
    };

    useEffect(() => {
        setPrinterName(printer.printer_name);
        setMacAddress(printer.printer_mac);
        setPrinterBrand(setSelectState("printer_brand", printer.printer_brand));
        setLaneNo(setSelectState("lane_no", printer.lane_no));
        setPrinterType(setSelectState("printer_type", printer.printer_type));
        setPrintJobs(printer.print_jobs);
    }, [printer]);

    const handleBlur = () => {
        if (!validateMacAddress(printer_mac)) {
            setMacError('Invalid MAC address format. Use XX:XX:XX:XX:XX:XX');
        } else {
            setMacError('');
        }
    };

    const disableEmpty = (Array.isArray(print_jobs) && print_jobs.length === 0)
    const disableUpdate = macError || ((printer.printer_name === printer_name) && (printer.printer_mac === printer_mac) && (printer.printer_brand === printer_brand?.value) && (printer.lane_no === lane_no?.value) && (printer.printer_type === printer_type?.value))

    return (
        <Card className="w-full p-4 mb-4 shadow-lg">
            <div className="flex flex-col lg:flex-row">
                <div className="flex-grow space-y-4">
                    <div>
                        <Input
                            type={"text"}
                            value={printer_name}
                            label={t("Printer Name")}
                            placeholder={t("Enter printer name")}
                            onChange={(e) => setPrinterName(e.target.value)}
                            className={"h-12 bg-secondary-50"}
                        />

                        {/* <label htmlFor={`printer_name-${index}`} className="block mb-1 text-sm font-medium text-gray-700">
                            {t("Printer Name")}
                        </label>
                        <input
                            id={`printer_name-${index}`}
                            value={printer_name}
                            onChange={(e) => setPrinterName(e.target.value)}
                            placeholder="Enter printer name"
                            className="w-full p-2 border rounded"
                        /> */}
                    </div>

                    <div>
                        <Input
                            type={"text"}
                            value={printer_mac}
                            label={t("Printer MAC Address")}
                            placeholder={t("Enter MAC address")}
                            onChange={(e) => setMacAddress(e.target.value)}
                            className={"h-12 bg-secondary-50"}
                            onBlur={handleBlur}
                            error={macError}
                        />
                        {macError && <div className="mt-1 text-sm text-red-500">{macError}</div>}
                        {/* <label htmlFor={`printer_mac-${index}`} className="block mb-1 text-sm font-medium text-gray-700">
                            {t("Printer MAC Address")}
                        </label>
                        <input
                            id={`printer_mac-${index}`}
                            value={printer_mac}
                            onChange={(e) => setMacAddress(e.target.value)}
                            onBlur={handleBlur}
                            placeholder="Enter MAC address"
                            className={`border rounded p-2 w-full ${macError ? 'border-red-500' : 'border-gray-300'}`}
                        /> */}
                        {/* {macError && <div className="mt-1 text-sm text-red-500">{macError}</div>} */}
                    </div>

                    <div>
                        <ReactSelect
                            label={t("Printer Brand")}
                            labelProps={"text-secondary-500 !text-base font-medium pb-1"}
                            defaultValue={printer_brand}
                            options={printerBrandOptions(t)}
                            disabled
                            name={`printer_brand-${index}`}
                            value={printer_brand}
                            onChange={(e) => {
                                setPrinterBrand(e)
                            }}
                        />
                        {/* <label htmlFor={`printer_brand-${index}`} className="block mb-1 text-sm font-medium text-gray-700">
                            {t("Printer Brand")}
                        </label>
                        <select
                            id={`printer_brand-${index}`}
                            value={printer_brand}
                            disabled={true}
                            onChange={(e) => setPrinterBrand(e.target.value)}
                            className="w-full p-2 border rounded"
                        >
                            <option value="STAR">{t("Star Micronics")}</option>
                            <option value="EPSON">{t("Epson")}</option>
                        </select> */}
                    </div>

                    {/* Lane no */}
                    <div>
                        <ReactSelect
                            label={t("Lane")}
                            labelProps={"text-secondary-500 !text-base font-medium pb-1"}
                            defaultValue={lane_no}
                            options={laneNumberOptions}
                            name={`lane_no-${index}`}
                            value={lane_no}
                            onChange={(e) => {
                                setLaneNo(e)
                            }}
                        />
                        {/* <label htmlFor={`lane_no-${index}`} className="block mb-1 text-sm font-medium text-gray-700">
                            {t("Lane")}
                        </label>
                        <select
                            id={`lane_no-${index}`}
                            value={lane_no}
                            onChange={(e) => setLaneNo(parseInt(e.target.value))}
                            className="w-full p-2 border rounded"
                        >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                        </select> */}
                    </div>


                    {/* Printer Type */}
                    <div>
                        <ReactSelect
                            label={t("Printer Type")}
                            labelProps={"text-secondary-500 !text-base font-medium pb-1"}
                            defaultValue={printer_type}
                            disabled
                            options={printerTypeOptions(t)}
                            name={`printer_type-${index}`}
                            value={printer_type}
                            onChange={(e) => {
                                setPrinterType(e)
                            }}
                        />
                        {/* <label htmlFor={`printer_type-${index}`} className="block mb-1 text-sm font-medium text-gray-700">
                            {t("Printer Type")}
                        </label>
                        <select
                            id={`printer_type-${index}`}
                            value={printer_type}
                            disabled={true}
                            onChange={(e) => setPrinterType(e.target.value)}
                            className="w-full p-2 border rounded"
                        >
                            <option value="RECEIPT">{t("Receipt")}</option>
                            <option value="LABEL">{t("Label")}</option>
                        </select> */}
                    </div>

                    {/* Print Jobs */}
                    <div>
                        <Card className="w-full p-6 shadow-lg bg-gradient-to-r from-primary-400 to-secondary-900">
                            <div className="flex items-center justify-between">
                                <div>
                                    <Typography variant="h5" className="text-white">
                                        {t("Print Jobs")}
                                    </Typography>
                                    <Typography variant="h1" className="text-white">
                                        {print_jobs.length}
                                    </Typography>
                                    <Typography variant="small" className="text-white">
                                        {t("Currently in Queue")}
                                    </Typography>
                                </div>
                                <PrintReceiptIcon />
                            </div>
                        </Card>
                    </div>
                </div>

                {/* Right column - Buttons */}
                <div className="flex flex-row flex-wrap justify-start ml-4 space-y-2 gap-x-2 lg:flex-col sm:flex-col sm:mt-2 md:flex-row">
                    <Button
                        onClick={() => onRemove(printer_id)}
                        className="text-white bg-red-500 lg:h-auto md:h-full md:mt-2"
                    >
                        {t("Remove")}
                    </Button>
                    <Button
                        disabled={disableUpdate}
                        onClick={() => onChange(printer_id, { printer_name, printer_mac: printer_mac, printer_brand: printer_brand?.value, lane_no: lane_no?.value, printer_type: printer_type?.value })}
                        className="text-white bg-primary-400"
                    >
                        {t("Update")}
                    </Button>
                    <Button
                        onClick={() => onTest(printer_id, lane_no)}
                        className="text-white bg-primary-400"
                    >
                        {t("Test")}
                    </Button>
                    <Button
                        disabled={disableEmpty}
                        onClick={() => onEmpty(printer_id)}
                        className="text-white bg-primary-400"
                    >
                        {t("Empty Queue")}
                    </Button>
                </div>
            </div>
        </Card>
    );
};


export default EditablePrinterCard;