import React, { useState, useEffect } from "react";
import { Card, ListItem, List, Button, Typography } from "@material-tailwind/react";
import { t } from "i18next";
import { addPrinterToLocation, emptyPrinterJobsQueue, getLocationPrinterSettings, removePrinterFromLocation, testLocationPrinter, updateLocationPrinter } from "../../store/settings/integration/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import { useLayoutEffect } from "react";
import { PrintReceiptIcon, RefreshIcon } from "../../components/svgs";
import ReactSelect from "../../components/common/ReactSelect";
import EditablePrinterCard from "../../components/settings/integration/EditablePrinterCard";
import emptyPrinter from "../../components/svgs/index";
import EmptyPrinter from "../../components/svgs/EmptyPrinter";
import Layout from "../../layout";


const IntegrationPrinter = () => {
    const dispatch = useDispatch();

    // Lane Number - store?.setting?.user_device_setting?.lane_number
    // OrgId - store?.setting?.defaultOrgLocation?._id
    // Location Name - store?.setting?.defaultOrgLocation?.location_name
    // Location Address - store?.setting?.defaultOrgLocation?.location_address
    // Org Name - store?.setting?.defaultOrgLocation?.organization?.name
    // Footer - store?.setting?.defaultOrgLocation?.organization?.footer
    // Currency - store?.setting?.defaultOrgLocation?.organization_setting?.currency?.symbol

    const {
        isLoading,
        defaultOrgLocationId,
        isProfileLoading,
        printersList,
        defaultOrgLocation,
        org_name,
        footer,
    } = useSelector((store) => ({
        defaultOrgLocation: store?.setting?.defaultOrgLocation,
        defaultOrgLocationId: store?.setting?.defaultOrgLocation?._id,
        isProfileLoading: store?.setting?.isProfileLoading,
        printersList: store?.integration?.printers?.printersList || [], // Ensure it's an array
        org_name: store?.setting?.organization?.name,
        footer: store?.setting?.organization?.footer,
    }));

    const [printers, setPrinters] = useState([]);

    // Dispatch get printers
    useLayoutEffect(() => {
        if (defaultOrgLocationId) {
            dispatch(getLocationPrinterSettings({ defaultOrgLocationId }));
        }
    }, [dispatch, defaultOrgLocationId]);

    useEffect(() => {
        // Initialize printers state from printersList
        if (printersList?.length > 0) {
            setPrinters(printersList?.map(printer => ({
                printer_id: printer._id,
                printer_name: printer.printer_name,
                printer_mac: printer.printer_mac,
                printer_brand: printer.printer_brand,
                lane_no: printer.lane_no,
                printer_type: printer.printer_type,
                print_jobs: printer.print_jobs
            })));
        }
        else {
            setPrinters([])
        }

    }, [printersList]);

    const handleUpdatePrinter = (printer_id, printer_data) => {
        dispatch(updateLocationPrinter({ printer_id, printer_data, defaultOrgLocationId }));
    };

    const handleRemovePrinter = (printer) => {
        const { printer_id } = printer;
        dispatch(removePrinterFromLocation({ printer_id, defaultOrgLocationId }));
    };

    const handleEmptyPrinter = (printer_id) => {
        dispatch(emptyPrinterJobsQueue({ printer_id, defaultOrgLocationId }))
    }

    const handleTestPrint = (printer_id, lane_no) => {
        const testPrintBody = {
            printer_id,
            defaultOrgLocationId,
            lane_no,
            receipt_type: "BILL",
            location_name: defaultOrgLocation?.location_name,
            location_address: defaultOrgLocation?.location_address,
            org_name,
            footer,
        };

        dispatch(testLocationPrinter(testPrintBody));
    }

    const handleAddPrinter = () => {
        const printer_data = {
            printer_name: 'New Printer',
            printer_mac: '00:00:00:00:00:A0',
            paper_width: '80',
            dot_width: 0,
            printer_brand: 'STAR',
            printer_type: 'RECEIPT',
            lane_no: 1
        }
        dispatch(addPrinterToLocation({ printer_data, defaultOrgLocationId }));
    }

    return (
        <Layout>
            <div className="grow overflow-y-auto xl:h-[calc(100vh-132px)] h-[calc(100vh-106px)]">
                <div className="px-4 py-5 mb-5 bg-white rounded-theme px ">
                    {isProfileLoading && (
                        <div className="absolute z-20 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
                            <Loader />
                        </div>
                    )}
                    <div className="px-4 py-5 mb-5 bg-white rounded-theme px">
                        <div className="flex items-center justify-between mb-3">
                            <div className="text-base font-semibold text-info-500 lg:text-xl">
                                {t("Printer Settings")}
                            </div>
                            <div className="flex items-center space-x-2">
                                <Button
                                    onClick={() => dispatch(getLocationPrinterSettings({ defaultOrgLocationId }))}
                                    className="p-2 bg-gray-100 rounded-full hover:bg-gray-200"
                                >
                                    <RefreshIcon />
                                </Button>

                                <Button
                                    loading={isLoading}
                                    onClick={() => handleAddPrinter()}
                                    className="bg-primary-400 flex items-center justify-center text-center border border-primary-400 lg:text-base text-sm_20 font-medium h-[46px] text-white rounded-theme capitalize"
                                >
                                    {t("Add Printer")}
                                </Button>
                            </div>
                        </div>

                        <List className="!p-0">
                            {printers?.length > 0 ? (
                                printers?.map((printer, index) => (
                                    <ListItem key={index}>
                                        <EditablePrinterCard
                                            printer={printer}
                                            index={index}
                                            onChange={(printerId, updatedPrinter) => handleUpdatePrinter(printerId, updatedPrinter)}
                                            onRemove={() => handleRemovePrinter(printer)}
                                            onTest={(printerId, laneNo) => handleTestPrint(printerId, laneNo)}
                                            onEmpty={(printerId) => handleEmptyPrinter(printerId)}
                                        />
                                    </ListItem>
                                ))
                            ) : (
                                <div className="flex items-center justify-center h-full">
                                    <div>
                                        <EmptyPrinter />
                                        <span className="flex justify-center mt-3 xl:text-base text-sm_18 text-secondary-400">
                                            {t("No printer found")}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </List>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default IntegrationPrinter;