import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowsRotate,
  faEllipsisVertical,
  faPowerOff,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/logo/quetzal-logo.svg";
import Input from "../components/common/Input";
import { checkRoutesAvailability } from "../utils/enums";
import { getUserDetails } from "../utils/utils";
import {
  InventoryNavTab,
  RegisterNavTab,
  ReportNavTab,
  SettingsNavTab,
} from "./components/navigationTab";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import Modal from "../components/common/Modal";
import SwitchLocation from "../components/settings/location/SwitchLocation";
import { valuesIn } from "lodash";
import { locationState } from "../store/settings/location/state";
import { profileAction } from "../store/settings/settings.fetch";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { SettingSvg } from "../components/svgs";

function Header({ search, handleChange }) {
  const location = useLocation();
  const navigate = useNavigate();
  const userDetail = getUserDetails();
  const { t } = useTranslation();
  const defaultOrgLocation = useSelector(
    (store) => store?.setting?.defaultOrgLocation
  );
  const queryClient = useQueryClient();

  const [openSwitchLocation, setOpenSwitchLocation] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [isSettingMenuOpen, setIsSettingMenuOpen] = useState(false); // Track menu open state
  return (
    <Fragment>
      <header className="flex items-center bg-white w-full xl:px-[30px] lg:px-6 px-4 fixed top-0 z-[999]">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-3 py-5 lg:gap-5">
            <Link
              href="#"
              className="xl:min-w-[135px] xl:h-[50px] min-w-[100px] h-[36px]"
            >
              <img
                src={Logo}
                alt="logo"
                className="flex-shrink-0 w-full h-full"
              />
            </Link>
            {checkRoutesAvailability.REGISTER.includes(location.pathname) ||
              location.pathname !== "/register" ? (
              ""
            ) : (
              <Fragment>
                <div className="relative xl:min-w-96">
                  <Input
                    type={"search"}
                    iconStart={
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="text-secondary-500"
                      />
                    }
                    className={
                      "px-3 pl-11 !font-medium text-sm_18 h-[46px] placeholder:text-secondary-400 placeholder:text-sm bg-secondary-50 text-info-400 "
                    }
                    placeholder={t("Search Product")}
                    value={search}
                    onChange={handleChange}
                  />
                </div>
                {/* <Menu placement="bottom-end">
                  <MenuHandler>
                    <IconButton
                      className="bg-transparent flex justify-center items-center h-[46px] hover:shadow shadow-none text-secondary-400 border border-secondary-200"
                      size="lg"
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        fontSize={20}
                      />
                    </IconButton>
                  </MenuHandler>
                  <MenuList className="p-0">
                    <MenuItem
                      className="py-3 text-sm capitalize rounded-none text-info-400"
                      onClick={() => navigate("/register/cash-drawer")}
                    >
                      {t("cash drawer")}
                    </MenuItem>
                    <MenuItem
                      className="py-3 text-sm capitalize rounded-none text-info-400"
                      onClick={() => navigate("/register/transaction")}
                    >
                      {t("transaction")}
                    </MenuItem>
                    <MenuItem
                      className="py-3 text-sm capitalize rounded-none text-info-400"
                      onClick={() => navigate("/register/daily-summary")}
                    >
                      {t("daily summary")}
                    </MenuItem>
                  </MenuList>
                </Menu> */}
              </Fragment>
            )}
          </div>
          <div className="flex items-center xl:gap-x-6 gap-x-3">
            <ul className="flex items-center xl:gap-x-10 gap-x-3 overflow-x-scroll 2xl:max-w-max lg:max-w-[600px] max-w-[450px] mx-5">
              {checkRoutesAvailability.REGISTER.includes(location.pathname) ? (
                <RegisterNavTab location={location} />
                // ) : checkRoutesAvailability.SETTINGS.includes(
                //   location.pathname
                // ) ? (
                //   <SettingsNavTab location={location} userDetail={userDetail} />
              ) : checkRoutesAvailability.INVENTORY.includes(
                location.pathname
              ) ? (
                <InventoryNavTab location={location} />
              ) : checkRoutesAvailability.REPORT.includes(location.pathname) ? (
                <ReportNavTab location={location} />
              ) : (
                ""
              )}
            </ul>

            {userDetail?.section_permission.includes("setting") && (
              <Menu open={isSettingMenuOpen}
                handler={setIsSettingMenuOpen}>
                <MenuHandler>
                  <div>
                    <button className={`p-2 rounded-full ${location.pathname.includes("/settings") || isSettingMenuOpen ? "bg-primary-150" : ""} hover:bg-primary-150`}
                    >
                      <SettingSvg />
                    </button>
                    <p className={`-ml-0.5 lg:text-sm text-sm_18 text-info-400 ${location.pathname.includes("/settings") || isSettingMenuOpen ? "font-semibold" : ""}`}>Settings</p>
                  </div>

                </MenuHandler>
                <MenuList className="p-0">
                  <SettingsNavTab location={location} userDetail={userDetail} />
                </MenuList>
              </Menu>
            )}

            {/* <ul className="flex items-center xl:gap-x-10 gap-x-3 overflow-x-scroll 2xl:max-w-max lg:max-w-[600px] max-w-[450px] mx-5">
              <Menu>
                {checkRoutesAvailability.REGISTER.includes(location.pathname) ? (
                  <RegisterNavTab location={location} />
                ) : checkRoutesAvailability.SETTINGS.includes(
                  location.pathname
                ) ? (
                  <MenuList className="p-0">
                    <SettingsNavTab location={location} userDetail={userDetail} />
                  </MenuList>
                ) : checkRoutesAvailability.INVENTORY.includes(
                  location.pathname
                ) ? (
                  <InventoryNavTab location={location} />
                ) : checkRoutesAvailability.REPORT.includes(location.pathname) ? (
                  <ReportNavTab location={location} />
                ) : (
                  ""
                )}

                <MenuHandler>
                  <button className="p-2 rounded-full hover:bg-gray-200">
                    <SettingSvg />
                  </button>
                </MenuHandler>
              </Menu>
            </ul> */}

            <div className="flex items-center justify-end py-5 xl:gap-x-6 gap-x-3">
              <Menu placement="bottom-end">
                <MenuHandler>
                  <button className="outline-none focus:outline-none">
                    <div
                      className="flex items-center gap-x-3 "
                      onClick={() => setOpenPopover(!openPopover)}
                    >
                      <Avatar
                        src={userDetail?.image}
                        alt="avatar"
                        className="xl:w-12 xl:h-12 w-9 h-9"
                      />
                      <div className="flex flex-col text-start">
                        <h3 className="text-base font-semibold capitalize">
                          {userDetail?.username}
                        </h3>
                        <p className="capitalize text-sm_18">
                          {defaultOrgLocation?.location_name}
                        </p>
                      </div>
                      <FontAwesomeIcon
                        icon={openPopover ? faAngleUp : faAngleDown}
                      />
                    </div>
                  </button>
                </MenuHandler>
                <MenuList className="p-0">
                  <MenuItem
                    className="flex items-center justify-start py-3 min-w-[250px] text-sm capitalize rounded-none gap-x-4 focus:text-primary-400 focus:bg-primary-150"
                    onClick={() => setOpenSwitchLocation(true)}
                  >
                    <FontAwesomeIcon
                      icon={faArrowsRotate}
                      fontSize={18}
                      className=""
                    />
                    {t("Switch Location")}
                  </MenuItem>
                  <MenuItem
                    className="flex items-center justify-start py-3 text-sm capitalize rounded-none gap-x-4 focus:text-primary-400 focus:bg-primary-150"
                    onClick={() => {
                      navigate("/Account");
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} fontSize={18} className="" />
                    {t("Account Setting")}
                  </MenuItem>
                  <MenuItem
                    className="flex items-center justify-start py-3 text-sm capitalize rounded-none focus:text-primary-400 focus:bg-primary-150 gap-x-4"
                    onClick={() => {
                      localStorage.removeItem("auth_token");
                      localStorage.removeItem("user");
                      localStorage.removeItem("refresh_token");
                      localStorage.removeItem("pinAccess");
                      localStorage.removeItem("primaryColor");
                      localStorage.removeItem("language");
                      navigate("/");
                      queryClient.removeQueries(); // This will remove all cached queries globally
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      fontSize={18}
                      className=""
                    />
                    {t("Log out")}
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </header>
      <Modal
        open={openSwitchLocation}
        handler={() => setOpenSwitchLocation(false)}
      >
        <SwitchLocation
          openSwitchLocation={openSwitchLocation}
          handler={() => setOpenSwitchLocation(false)}
        />
      </Modal>
    </Fragment >
  );
}

export default Header;
