import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import PlusIcon from "../../assets/icon/plus-icon.svg";
import MinusIcon from "../../assets/icon/minus-icon.svg";
import { ErrorMessage, Form, Formik } from "formik";
import { values } from "lodash";
import {
  createCashDrawerOptions,
  formatCurrencyWithNumberValue,
  getUserDetails,
} from "../../utils/utils";
import Input from "../../components/common/Input";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  createCashDrawerRequest,
  // getListCashDrawerRequest,
} from "../../store/register/cashDrawer/action";
import CashDrawerList from "../../components/cash-drawer/CashDrawerList";
import { Button, IconButton } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import ReactSelect from "../../components/common/ReactSelect";
import { useQueryClient } from "@tanstack/react-query";
import badgeColors from "@material-tailwind/react/theme/components/badge/badgeColors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const CashDrawer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { isLoading, userDeviceSetting, cashDrawerList } = useSelector(
    (store) => ({
      isLoading: store?.cashDrawer?.createCashDrawer?.loading,
      userDeviceSetting: store?.setting?.user_device_setting,
      cashDrawerList: store?.cashDrawer?.cashDrawersList?.data?.cashDrawer,
    })
  );
  const createCashDrawerOptions = [
    { label: `${t("Cash In Amount")}`, value: "cash in amount" },
    {
      label: `${t("Amount Removed To Close Drawer")}`,
      value: "amount removed to close drawer",
    },
    { label: `${t("Amount Paid Out")}`, value: "amount paid out" },
    { label: `${t("Drawer Count")}`, value: "drawer count" },
    { label: `${t("Count To Open Drawer")}`, value: "count to open drawer" },
  ];

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [cashDrawersData, setCashDrawersData] = useState([]);
  const [adjustments, setAdjustments] = useState([]);
  const userDetail = getUserDetails();
  const queryClient = useQueryClient();
  // cash in amount dropdown
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "bg-white",
      borderColor: state.isFocused ? "#fff" : "#cbd4e1",
      boxShadow: state.isFocused ? "0 0 0 1px #3182ce" : "none",
      "&:hover": {
        borderColor: "#000",
      },
      borderRadius: "8px",
      padding: "5px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#a0aec0",
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#2d3748",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      "&:hover": {
        color: "#2b6cb0",
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f7fafc" : "#fff",
      color: state.isSelected ? "#3182ce" : "#2d3748",
      padding: 10,
    }),
  };

  useEffect(() => {
    if (userDeviceSetting?.lane_number) {
      setAdjustments([]);
      queryClient.removeQueries(["cashDrawerList"]);
      queryClient.refetchQueries({
        queryKey: ["cashDrawerList"],
      });
    }
  }, [userDeviceSetting?.lane_number]);

  return (
    <Layout>
      {/* <div className="flex items-center  p-3 mb-4 bg-white rounded-theme xl:py-5 lg:px-5 lg:py-4 gap-1.5">
        <IconButton
          type="button"
          variant="text"
          className=""
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} fontSize={18} />
        </IconButton>
        <div className="text-base font-semibold lg:text-lg 2xl:font-bold text-info-500 2xl:text-xl">
          Cash Drawer
        </div>
      </div> */}
      <div className="grid 2xl:grid-cols-7 xl:grid-cols-6 grid-cols-5 gap-4 xl:h-[calc(100vh-116px)] h-[calc(100vh-100px)] w-full">
        <div className="col-span-3 bg-white 2xl:col-span-5 xl:col-span-4 rounded-theme ">
          <CashDrawerList
            adjustments={adjustments}
            setAdjustments={setAdjustments}
          />
        </div>
        <div className="relative col-span-2 p-3 bg-white 2xl:col-span-2 rounded-l-theme lg:p-5">
          <Formik
            enableReinitialize
            initialValues={{
              drawer_action: "",
              amount: "",
            }}
            validationSchema={Yup.object().shape({
              drawer_action: Yup.string().required(
                t("Please select anyone drawer action")
              ),
              amount: Yup.number()
                .required(t("Please enter amount"))
                .min(0, t("Amount cannot be negative"))
                .when("drawer_action", (drawer_action, schema) => {
                  const actionsWithLimit = [
                    "amount removed to close drawer",
                    "amount paid out",
                  ];

                  // Handle case where drawer_action might be an array
                  const actionValue = Array.isArray(drawer_action)
                    ? drawer_action[0]
                    : drawer_action;

                  console.log(actionValue, "drawer_action value........");

                  // Conditional validation based on the extracted drawer_action value
                  if (actionsWithLimit.includes(actionValue)) {
                    return schema.max(
                      adjustments?.[adjustments.length - 1]?.runningTotal || 0,
                      t("Amount cannot more than running total")
                    );
                  }
                  return schema;
                }),
            })}
            onSubmit={(values, { resetForm }) => {
              const data = values;
              dispatch(createCashDrawerRequest({ body: data }))
                .then((res) => {
                  if (res?.status === 200) {
                    resetForm();
                    // setCashDrawersData([]);
                    // if (page === 1 && cashDrawerList?.length <= 10) {
                    //   dispatch(
                    //     getListCashDrawerRequest({
                    //       page: 1,
                    //       limit,
                    //     })
                    //   );
                    // }
                    queryClient.invalidateQueries({
                      queryKey: ["cashDrawerList"],
                    });

                    // console.log(page, "page.......");
                    // if (page > 1) {
                    //   setPage(1);
                    // }
                    console.log(res, "success");
                  }
                })
                .catch((error) => {
                  console.error("Error creating cash drawer:", error);
                });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => {
              const incrementAmount = () => {
                setFieldValue("amount", (values.amount || 0) + 1);
              };

              const decrementAmount = () => {
                setFieldValue("amount", (values.amount || 0) - 1);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <div className="xl:mb-[30px] lg:mb-5 mb-4 lg:mt-2 ">
                    <label
                      htmlFor="mail-input"
                      className="block mb-1 text-xs font-medium lg:mb-3 lg:text-sm_18 text-secondary-400"
                    >
                      {t("Lane")} {userDeviceSetting?.lane_number} :
                    </label>
                    <Input
                      type="email"
                      id="mail-input"
                      placeholder="Holder Name"
                      value={userDetail.email}
                      disabled
                      className="bg-gray-100"
                    />
                  </div>
                  <div className="xl:mb-[30px] lg:mb-5 mb-4 bg-secondary-50 p-3 rounded-theme">
                    <div className="w-full mb-3 lg:mb-5">
                      <ReactSelect
                        // classNamePrefix="custom-select"
                        asterisk
                        labelProps={
                          "text-secondary-500 !text-base font-semibold pb-1 capitalize"
                        }
                        options={createCashDrawerOptions}
                        name="drawer_action"
                        value={
                          createCashDrawerOptions.find(
                            (option) => option.value === values.drawer_action
                          ) || ""
                        }
                        onChange={(selectedOption) => {
                          setFieldValue("drawer_action", selectedOption.value);
                        }}
                        placeholder={
                          language === "fr"
                            ? "Sélectionnez une action du tiroir"
                            : "Select Drawer Action"
                        }
                        // placeholder={t("Select Drawer Action")} // Set your custom placeholder here
                        styles={customStyles} // Apply custom styles here
                      />
                      <ErrorMessage
                        name="drawer_action"
                        component={"p"}
                        className="mt-1 text-sm font-medium text-red-500"
                      />
                    </div>
                    <div className="flex items-center justify-between gap-3 pr-4 transition-all duration-200 bg-white border lg:gap-5 border-secondary-200 rounded-theme focus:border-primary-400 focus:shadow">
                      <div>
                        <Input
                          type="number"
                          placeholder="50"
                          name="amount"
                          value={values.amount || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={" w-full border-none focus:shadow-none"}
                        />
                      </div>

                      <div className="flex items-center gap-4 lg:gap-10">
                        <div
                          className="lg:w-[18px] lg:h-[18px] w-[9px] h-[9px] flex items-center"
                          onClick={decrementAmount}
                        >
                          <img src={MinusIcon} alt="plus-icon" />
                        </div>
                        <div
                          className="lg:w-[18px] lg:h-[18px] w-[9px] h-[9px] flex items-center"
                          onClick={incrementAmount}
                        >
                          <img src={PlusIcon} alt="plus-icon" />
                        </div>
                      </div>
                    </div>
                    <ErrorMessage
                      name="amount"
                      component={"p"}
                      className="mt-1 text-sm font-medium text-red-500"
                    />
                  </div>
                  <Button
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                    className="bg-primary-400 font-medium  lg:text-base text-sm_18 text-white h-[46px] flex justify-center items-center lg:rounded-theme rounded-base w-full capitalize"
                  >
                    {t("Confirm")}
                  </Button>
                  {/* <div className="absolute xl:bottom-10 lg:bottom-6 bottom-5 w-[calc(100%-40px)]">
                    <div className="bg-secondary-100 rounded-theme lg:px-5 px-3 xl:py-6 lg:py-4 py-3.5 flex justify-between items-center">
                      <div className="font-medium xl:text-lg lg:text-base text-sm_18 text-secondary-400">
                        {t("Expected Cash :")}
                      </div>
                      <div className="font-bold text-info-500 xl:text-lg lg:text-base text-sm_18">
                        {formatCurrencyWithNumberValue("66.00")}
                      </div>
                    </div>
                  </div> */}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default CashDrawer;
