import React from "react";

const EmptyPrinter = () => {
    return (
        <svg width="314" height="314" viewBox="0 0 314 314" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M88.9552 60.1274C91.1152 60.1274 92.8666 58.376 92.8666 56.216C92.8666 54.056 91.1152 52.3064 88.9552 52.3064C86.7952 52.3064 85.0438 54.0578 85.0438 56.216C85.0456 58.3778 86.7952 60.1274 88.9552 60.1274ZM255.012 153.891H275.907C287.153 153.891 296.353 163.091 296.353 174.337C296.353 185.582 287.151 194.784 275.907 194.784H99.2296C81.019 194.784 66.1222 179.885 66.1222 161.676C66.1222 143.467 81.0208 128.569 99.2296 128.567C106.928 128.567 113.228 122.267 113.228 114.568C113.228 106.87 106.928 100.57 99.2296 100.57H82.3816C74.377 100.57 67.8286 94.0196 67.8286 86.015C67.8286 78.0104 74.377 71.4602 82.3816 71.4602H103.686C106.802 71.4602 109.353 68.9096 109.353 65.7938C109.353 62.678 106.802 60.1274 103.686 60.1274H99.9316C97.7806 60.1274 96.0202 58.367 96.0202 56.216C96.0202 54.065 97.7806 52.3064 99.9316 52.3064H114.008C114.008 52.3064 119.386 52.3064 124.575 52.3064C129.765 52.3064 130.343 58.4372 143.454 58.4372C163.72 58.4372 153.586 58.4372 173.852 58.4372C176.658 58.4372 178.955 60.734 178.955 63.5402C178.955 66.3464 176.658 68.6432 173.852 68.6432H168.953C164.886 68.6432 161.558 71.9714 161.558 76.0376C161.558 80.1038 164.886 83.4302 168.953 83.4302H183.702C203.617 83.4302 206.43 88.634 209.919 98.9858C213.407 109.338 231.522 110.173 231.522 110.173H256.292C262.421 110.173 267.434 115.186 267.434 121.315C267.434 127.442 262.419 132.457 256.292 132.457H255.012C249.119 132.457 244.295 137.279 244.295 143.174C244.295 149.069 249.119 153.891 255.012 153.891Z" fill="#E0E8F5" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M241.082 91.6112C219.27 82.775 199.227 92.9359 195.326 118.923C219.522 126.803 237.322 116.237 241.082 91.6112Z" fill="#C4D1EB" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M238.433 92.3995C238.596 92.3005 238.809 92.3527 238.908 92.5165C239.007 92.6803 238.955 92.8927 238.791 92.9917L223.377 102.332L232.268 107.682C232.431 107.781 232.484 107.993 232.385 108.157C232.286 108.321 232.073 108.373 231.909 108.274L222.708 102.737L218.427 105.331C218.372 105.423 218.273 105.486 218.159 105.495L214.878 107.484L223.822 114.912C223.968 115.035 223.989 115.252 223.867 115.398C223.745 115.546 223.527 115.566 223.381 115.443L214.253 107.862L210.407 110.193C210.394 110.202 210.381 110.209 210.369 110.216L206.463 112.583L212.865 118.307C213.008 118.435 213.02 118.653 212.892 118.795C212.765 118.937 212.547 118.95 212.405 118.822L205.845 112.959L195.508 119.223C195.344 119.322 195.132 119.27 195.033 119.106C194.934 118.942 194.986 118.73 195.15 118.631L202.101 114.419L201.2 107.674C201.174 107.485 201.306 107.311 201.495 107.286C201.684 107.26 201.858 107.392 201.884 107.581L202.746 114.028L209.827 109.737L207.678 98.0443C207.644 97.8571 207.768 97.6753 207.955 97.6411C208.144 97.6069 208.324 97.7311 208.358 97.9183L210.461 109.352L214.1 107.147L214.115 107.138L217.778 104.919L217.117 92.7901C217.106 92.5993 217.254 92.4355 217.445 92.4265C217.635 92.4157 217.799 92.5633 217.808 92.7541L218.449 104.512L222.521 102.044L222.542 102.031L226.896 99.3925L227.449 90.4195C227.46 90.2287 227.624 90.0847 227.814 90.0955C228.005 90.1063 228.149 90.2719 228.138 90.4609L227.615 98.9569L238.433 92.3995Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M94.24 85.5524C109.794 79.2524 124.086 86.4974 126.869 105.028C109.614 110.648 96.9202 103.113 94.24 85.5524Z" fill="#C4D1EB" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M96.1282 86.1157C96.0112 86.0455 95.86 86.0815 95.7898 86.1985C95.7196 86.3155 95.7556 86.4667 95.8726 86.5369L106.863 93.1969L100.524 97.0111C100.407 97.0813 100.369 97.2325 100.439 97.3495C100.509 97.4665 100.661 97.5043 100.778 97.4341L107.339 93.4867L110.391 95.3371C110.431 95.4019 110.501 95.4469 110.582 95.4541L112.922 96.8725L106.545 102.17C106.44 102.256 106.426 102.413 106.512 102.517C106.599 102.622 106.755 102.636 106.86 102.55L113.369 97.1443L116.112 98.8057C116.121 98.8111 116.13 98.8165 116.139 98.8219L118.925 100.51L114.36 104.591C114.26 104.683 114.251 104.838 114.341 104.938C114.431 105.039 114.587 105.048 114.688 104.958L119.366 100.777L126.739 105.244C126.856 105.315 127.007 105.279 127.077 105.162C127.148 105.045 127.112 104.893 126.995 104.823L122.037 101.819L122.68 97.0093C122.698 96.8743 122.604 96.7501 122.469 96.7321C122.334 96.7141 122.21 96.8077 122.192 96.9427L121.578 101.54L116.529 98.4799L118.063 90.1423C118.088 90.0091 117.998 89.8795 117.865 89.8543C117.732 89.8291 117.602 89.9191 117.577 90.0523L116.078 98.2045L113.482 96.6313L113.471 96.6241L110.859 95.0419L111.331 86.3929C111.338 86.2561 111.234 86.1409 111.097 86.1337C110.96 86.1265 110.845 86.2309 110.838 86.3659L110.381 94.7503L107.477 92.9899L107.463 92.9809L104.358 91.0999L103.964 84.7009C103.955 84.5659 103.839 84.4615 103.703 84.4705C103.568 84.4795 103.463 84.5947 103.472 84.7315L103.845 90.7903L96.1282 86.1157Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 170.437C31.8124 161.601 51.8554 171.762 55.756 197.748C31.5586 205.629 13.7602 195.063 10 170.437Z" fill="#C4D1EB" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6496 171.223C12.4858 171.124 12.2734 171.177 12.1744 171.34C12.0754 171.504 12.1276 171.717 12.2914 171.816L27.7048 181.156L18.8146 186.505C18.6508 186.604 18.5986 186.817 18.6976 186.981C18.7966 187.144 19.009 187.197 19.1728 187.098L28.3744 181.561L32.6548 184.155C32.7106 184.246 32.8096 184.309 32.923 184.318L36.2044 186.307L27.2602 193.736C27.1126 193.858 27.0928 194.076 27.2152 194.222C27.3376 194.368 27.5554 194.389 27.7012 194.267L36.829 186.685L40.6756 189.016C40.6882 189.025 40.7008 189.033 40.7134 189.04L44.6194 191.407L38.2168 197.131C38.0746 197.259 38.062 197.476 38.1898 197.619C38.3176 197.761 38.5354 197.773 38.6776 197.646L45.2368 191.783L55.5742 198.047C55.738 198.146 55.9504 198.094 56.0494 197.93C56.1484 197.766 56.0962 197.554 55.9324 197.455L48.9808 193.243L49.8826 186.498C49.9078 186.309 49.7764 186.135 49.5874 186.109C49.3984 186.084 49.2238 186.216 49.1986 186.405L48.3364 192.852L41.2552 188.561L43.4044 176.868C43.4386 176.681 43.3144 176.499 43.1272 176.465C42.9382 176.431 42.7582 176.555 42.724 176.742L40.6216 188.176L36.982 185.971L36.9676 185.962L33.3046 183.742L33.9652 171.614C33.976 171.423 33.8284 171.259 33.6376 171.25C33.4468 171.24 33.283 171.387 33.274 171.578L32.6332 183.337L28.5598 180.87L28.54 180.857L24.1858 178.218L23.6332 169.245C23.6224 169.054 23.4586 168.91 23.2678 168.921C23.077 168.932 22.933 169.096 22.9438 169.287L23.4676 177.783L12.6496 171.223Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M289.169 137.027C260.805 143.716 247.829 168.333 262.493 197.383C292.582 188.021 303.002 164.601 289.169 137.027Z" fill="#B0C2E4" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M287.058 139.72C287.151 139.504 287.403 139.403 287.621 139.497C287.839 139.59 287.938 139.842 287.843 140.06L278.956 160.531L291.768 159.57C292.004 159.552 292.209 159.73 292.227 159.966C292.245 160.202 292.067 160.407 291.833 160.425L278.573 161.419L276.105 167.103C276.114 167.236 276.06 167.37 275.95 167.461L274.058 171.821L288.415 172.923C288.651 172.941 288.827 173.146 288.809 173.382C288.791 173.617 288.586 173.794 288.35 173.776L273.7 172.651L271.482 177.759C271.475 177.777 271.468 177.793 271.461 177.81L269.209 182.999L279.775 184.198C280.009 184.225 280.178 184.437 280.151 184.671C280.124 184.905 279.912 185.074 279.678 185.047L268.853 183.82L262.893 197.55C262.799 197.766 262.547 197.867 262.329 197.772C262.113 197.678 262.013 197.426 262.106 197.208L266.115 187.974L260.375 181.804C260.213 181.631 260.223 181.361 260.394 181.199C260.567 181.039 260.837 181.048 260.999 181.221L266.486 187.117L270.568 177.712L260.025 167.438C259.856 167.272 259.853 167.001 260.018 166.831C260.184 166.662 260.456 166.659 260.625 166.824L270.935 176.872L273.032 172.039L273.041 172.019L275.153 167.154L265.802 155.373C265.654 155.187 265.686 154.917 265.872 154.77C266.057 154.622 266.327 154.654 266.473 154.84L275.54 166.261L277.887 160.852L277.899 160.825L280.41 155.041L274.544 145.579C274.42 145.379 274.481 145.114 274.681 144.99C274.881 144.866 275.145 144.927 275.27 145.127L280.824 154.086L287.058 139.72Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M225.707 47C197.342 53.6888 184.364 78.3056 199.031 107.356C229.119 97.9922 239.538 74.5724 225.707 47Z" fill="#B0C2E4" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M223.593 49.6928C223.687 49.4768 223.939 49.376 224.157 49.4696C224.373 49.5632 224.474 49.8152 224.378 50.033L215.492 70.5044L228.304 69.5432C228.54 69.5252 228.745 69.7034 228.763 69.9392C228.781 70.175 228.603 70.3802 228.367 70.3982L215.106 71.3918L212.639 77.078C212.648 77.2112 212.594 77.3444 212.484 77.4362L210.592 81.7958L224.949 82.8974C225.185 82.9154 225.361 83.1206 225.343 83.3564C225.325 83.5922 225.12 83.7686 224.884 83.7506L210.234 82.6256L208.016 87.734C208.011 87.752 208.004 87.7682 207.995 87.7844L205.743 92.9738L216.309 94.1726C216.543 94.1996 216.712 94.412 216.685 94.646C216.658 94.88 216.446 95.0492 216.212 95.0222L205.386 93.7946L199.427 107.525C199.333 107.741 199.081 107.842 198.863 107.746C198.645 107.651 198.546 107.401 198.642 107.183L202.65 97.949L196.91 91.7786C196.75 91.6058 196.759 91.3358 196.93 91.1738C197.103 91.0136 197.373 91.0226 197.535 91.1936L203.021 97.0904L207.104 87.6854L196.561 77.411C196.392 77.2454 196.388 76.9736 196.554 76.8044C196.719 76.6352 196.991 76.6316 197.16 76.7972L207.471 86.8448L209.57 82.0118L209.579 81.992L211.69 77.1266L202.339 65.3456C202.191 65.1602 202.224 64.8902 202.409 64.7426C202.595 64.595 202.865 64.6274 203.012 64.8128L212.079 76.2338L214.426 70.8248L214.439 70.7978L216.95 65.0144L211.083 55.5518C210.959 55.352 211.02 55.0874 211.22 54.9632C211.42 54.839 211.685 54.9002 211.809 55.1L217.364 64.0586L223.593 49.6928Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M105.204 53.741C125.43 58.511 134.684 76.0646 124.226 96.779C102.77 90.1028 95.3398 73.4024 105.204 53.741Z" fill="#B0C2E4" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M106.71 55.6616C106.644 55.5068 106.464 55.4366 106.309 55.5032C106.154 55.5698 106.084 55.7498 106.151 55.9046L112.487 70.5025L103.35 69.8168C103.182 69.8042 103.035 69.9302 103.022 70.0994C103.01 70.2668 103.136 70.4144 103.305 70.427L112.762 71.1362L114.522 75.1898C114.517 75.2834 114.555 75.3806 114.632 75.4454L115.982 78.554L105.746 79.3406C105.578 79.3532 105.452 79.5008 105.465 79.6682C105.477 79.8356 105.625 79.9616 105.792 79.949L116.24 79.1462L117.822 82.7894C117.825 82.802 117.831 82.8128 117.838 82.8254L119.444 86.5262L111.909 87.3812C111.741 87.401 111.621 87.5522 111.641 87.7196C111.66 87.887 111.812 88.0076 111.979 87.9878L119.699 87.1112L123.949 96.9032C124.016 97.058 124.196 97.1282 124.35 97.0616C124.505 96.995 124.575 96.815 124.509 96.6602L121.65 90.0758L125.744 85.6766C125.859 85.5542 125.852 85.3598 125.729 85.2464C125.607 85.1312 125.412 85.1384 125.299 85.2608L121.386 89.4656L118.475 82.7588L125.994 75.4328C126.114 75.314 126.118 75.1214 125.999 75.0008C125.88 74.8802 125.688 74.8766 125.567 74.9954L118.216 82.1594L116.72 78.7124L116.715 78.698L115.208 75.2294L121.875 66.8288C121.98 66.6974 121.958 66.5048 121.827 66.4004C121.694 66.296 121.503 66.3176 121.397 66.449L114.933 74.594L113.259 70.7366L113.25 70.7168L111.461 66.593L115.644 59.8466C115.732 59.7026 115.689 59.5154 115.547 59.4272C115.404 59.339 115.215 59.3822 115.127 59.5244L111.167 65.9126L106.71 55.6616Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3756 125.824C53.74 132.513 66.7162 157.129 52.0516 186.18C21.9628 176.818 11.5426 153.398 25.3756 125.824Z" fill="#B0C2E4" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.487 128.516C27.3934 128.3 27.1414 128.2 26.9236 128.295C26.7076 128.389 26.6068 128.641 26.7022 128.858L35.5888 149.33L22.7764 148.369C22.5406 148.351 22.3354 148.529 22.3174 148.765C22.2994 149 22.4776 149.206 22.7134 149.224L35.974 150.217L38.4418 155.902C38.4328 156.035 38.4868 156.168 38.5966 156.26L40.4884 160.619L26.1316 161.721C25.8958 161.739 25.7194 161.946 25.7374 162.18C25.7554 162.416 25.9606 162.592 26.1964 162.574L40.8466 161.449L43.0642 166.558C43.0714 166.576 43.0786 166.592 43.0858 166.608L45.3376 171.797L34.7716 172.996C34.5376 173.023 34.3684 173.236 34.3954 173.47C34.4224 173.704 34.6348 173.873 34.8688 173.846L45.694 172.618L51.6538 186.349C51.7474 186.565 51.9994 186.665 52.2172 186.57C52.4332 186.476 52.534 186.224 52.4404 186.007L48.4318 176.773L54.172 170.602C54.334 170.429 54.3232 170.159 54.1504 169.997C53.9776 169.837 53.7076 169.846 53.5456 170.017L48.0592 175.914L43.9768 166.509L54.5194 156.235C54.6886 156.069 54.6922 155.797 54.5266 155.628C54.361 155.459 54.0892 155.455 53.92 155.621L43.6096 165.668L41.5108 160.835L41.5018 160.816L39.3886 155.95L48.7396 144.169C48.8872 143.984 48.8548 143.714 48.6694 143.566C48.484 143.419 48.214 143.451 48.0682 143.636L39.0016 155.059L36.6544 149.65L36.6418 149.623L34.1308 143.84L39.997 134.377C40.1212 134.177 40.06 133.913 39.8602 133.789C39.6604 133.664 39.3958 133.726 39.2716 133.925L33.7168 142.884L27.487 128.516Z" fill="white" />
            <path d="M259.599 133.78H55.9666V216.056H259.599V133.78Z" fill="#C4D1EB" />
            <path d="M263.885 138.321H259.599V216.054H263.885V138.321Z" fill="#8FA3CF" />
            <path d="M55.9666 138.321H51.6808V216.054H55.9666V138.321Z" fill="#8FA3CF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M88.4908 216.056H227.075V152.377H88.4908V216.056ZM88.4908 149.892H227.075V146.292H88.4908V149.892ZM88.4908 138.321H227.075V133.78H88.4908V138.321Z" fill="#B0C2E4" />
            <path d="M251.051 205.6H235.623V210.399H251.051V205.6Z" fill="#8FA3CF" />
            <path d="M211.692 183.06H103.875V216.056H211.692V183.06Z" fill="#9DB2D9" />
            <path d="M207.726 187.26H107.839V216.054H207.726V187.26Z" fill="#8FA3CF" />
            <path d="M207.726 187.26H107.839V199.557H207.726V187.26Z" fill="#8FA3CF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M55.9666 146.292H88.4908V138.321H55.9666V146.292ZM227.075 146.292H259.599V138.321H227.075V146.292Z" fill="#B0C2E4" />
            <path d="M227.075 138.321H88.4908V146.292H227.075V138.321Z" fill="#9DB2D9" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M55.9666 152.377H88.4908V149.892H55.9666V152.377ZM227.075 152.377H259.599V149.892H227.075V152.377Z" fill="#B0C2E4" />
            <path d="M227.075 149.892H88.4908V152.377H227.075V149.892Z" fill="#9DB2D9" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M66.7432 162.234C66.7432 165.264 69.1984 167.719 72.2278 167.719C75.2572 167.719 77.7124 165.264 77.7124 162.234C77.7124 159.205 75.2572 156.75 72.2278 156.75C69.2002 156.748 66.7432 159.203 66.7432 162.234Z" fill="#8FA3CF" />
            <path d="M211.037 87.1562H104.529V133.78H211.037V87.1562Z" fill="#8FA3CF" />
            <path d="M203.892 66.9297H111.675V133.778H203.892V66.9297Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M111.605 66.818L111.545 100.302L111.466 133.78L111.464 133.987H111.673L203.891 134.118H204.231V133.778L204.135 66.9296V66.6848H203.892L157.715 66.764L111.605 66.818ZM111.704 66.9836L157.852 67.0934L203.892 67.1726L203.649 66.9296L203.554 133.778L203.894 133.438L111.677 133.569L111.885 133.778L111.806 100.406L111.704 66.9836Z" fill="#9DB2D9" />
            <path d="M78.3136 171.198H66.1438V172.946H78.3136V171.198Z" fill="#8FA3CF" />
            <path d="M78.3136 174.541H66.1438V176.289H78.3136V174.541Z" fill="#8FA3CF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M72.9478 252.222H242.618L211.692 216.056H103.875L72.9478 252.222Z" fill="#9DB2D9" />
            <path d="M242.618 252.222H72.9478V257.107H242.618V252.222Z" fill="#8FA3CF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M200.912 199.168C171.75 199.138 142.588 199.107 113.426 199.078C97.876 219.148 102.306 239.226 85.7044 259.296C133.831 259.296 181.958 259.296 230.084 259.296C212.982 239.253 216.962 219.213 200.912 199.168Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M200.912 199.168C208.608 209.205 212.117 219.237 215.34 229.272C218.3 239.28 220.976 249.288 229.715 259.297C229.796 259.252 229.879 259.206 229.96 259.161C181.904 259.139 133.878 259.117 85.8826 259.096C86.0032 259.162 86.1238 259.229 86.2444 259.297C102.673 239.227 98.2162 219.15 113.637 199.08C113.536 199.123 113.435 199.167 113.333 199.21C142.575 199.194 171.768 199.181 200.912 199.168ZM200.912 199.168C171.732 199.095 142.601 199.021 113.52 198.949C113.45 198.949 113.379 198.949 113.309 198.949L113.216 199.078C97.534 219.147 101.939 239.226 85.1662 259.296C85.1068 259.364 85.0456 259.431 84.9862 259.497C85.1662 259.497 85.3462 259.497 85.528 259.497C133.786 259.476 182.015 259.454 230.21 259.434C230.334 259.434 230.457 259.434 230.581 259.434L230.455 259.297C221.534 249.262 218.78 239.229 215.722 229.194C212.36 219.184 208.704 209.178 200.912 199.168Z" fill="#B0C2E4" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M224.853 252.421C180.158 252.418 135.462 252.416 90.766 252.412C90.0568 253.582 89.2792 254.754 88.4224 255.924C134.709 255.926 180.995 255.928 227.282 255.93C226.398 254.76 225.591 253.591 224.853 252.421Z" fill="#B0C2E4" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M205.572 231.777C173.566 231.766 141.558 231.756 109.551 231.743C109.324 232.729 109.095 233.712 108.861 234.699C141.353 234.709 173.843 234.718 206.333 234.729C206.076 233.745 205.824 232.762 205.572 231.777Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M206.333 234.729C173.843 234.72 141.351 234.709 108.861 234.698C108.629 235.683 108.392 236.668 108.141 237.654C141.135 237.663 174.129 237.672 207.122 237.679C206.852 236.696 206.589 235.712 206.333 234.729Z" fill="#FDC032" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M204.814 228.825C173.291 228.812 141.765 228.8 110.24 228.789C110.01 229.774 109.781 230.758 109.551 231.743C141.558 231.754 173.566 231.766 205.572 231.777C205.32 230.794 205.068 229.81 204.814 228.825Z" fill="#D4D5D3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M204.031 225.875C173.006 225.86 141.98 225.848 110.955 225.833C110.712 226.818 110.476 227.803 110.242 228.789C141.767 228.802 173.291 228.814 204.816 228.825C204.557 227.842 204.297 226.859 204.031 225.875Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M139.794 216.985C131.01 216.979 122.228 216.974 113.444 216.969C111.245 223.863 109.916 230.758 108.141 237.654C118.034 237.658 127.927 237.66 137.818 237.663C138.48 230.769 138.963 223.877 139.794 216.985Z" fill="#BCBFC0" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M109.551 231.743C125.555 231.761 141.557 231.779 157.562 231.797C173.566 231.79 189.57 231.783 205.572 231.775C189.566 231.757 173.564 231.739 157.56 231.721C141.56 231.73 125.556 231.737 109.551 231.743ZM110.24 228.789C126.001 228.807 141.764 228.825 157.528 228.845C173.291 228.84 189.053 228.832 204.814 228.825C189.05 228.807 173.289 228.789 157.528 228.769C141.765 228.776 126.005 228.784 110.24 228.789ZM169.419 227.337C170.142 227.394 170.862 227.408 171.582 227.412C172.301 227.41 173.019 227.398 173.733 227.338C173.01 227.281 172.288 227.264 171.57 227.263C170.852 227.264 170.133 227.279 169.419 227.337ZM184.344 227.342C186.607 227.403 188.859 227.408 191.112 227.419C193.362 227.412 195.614 227.408 197.852 227.347C195.587 227.284 193.335 227.281 191.084 227.27C188.835 227.279 186.584 227.283 184.344 227.342Z" fill="#0C2B34" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M91.4266 241.045H224.348C218.62 234.337 215.465 227.63 213.053 220.921C210.72 214.45 209.091 207.978 206.519 201.507C206.177 200.653 205.822 199.8 205.444 198.947H109.286C103.472 212.98 103.028 227.013 91.4266 241.045Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M90.928 241.045C102.655 227.013 103.136 212.98 109.014 198.947H109.556C105.144 209.709 103.881 220.473 98.3458 231.234C97.7572 232.393 97.1128 233.548 96.4108 234.708C96.2668 234.945 96.1228 235.176 95.9716 235.413C95.707 235.838 95.4316 236.266 95.149 236.691C95.0104 236.893 94.8718 237.1 94.7332 237.303C94.5892 237.516 94.4398 237.732 94.2832 237.944C94.1338 238.156 93.9826 238.374 93.8206 238.585C93.268 239.344 92.6848 240.104 92.0656 240.864H91.5904C91.6984 240.923 91.8136 240.986 91.9216 241.045L92.0656 240.864C135.98 240.883 179.924 240.903 223.899 240.923L224.007 241.045C224.085 241.008 224.157 240.961 224.236 240.923H223.899C223.039 239.931 222.247 238.939 221.507 237.949C220.703 236.873 219.968 235.793 219.295 234.718C216.397 230.121 214.559 225.519 213.056 220.921C212.239 218.416 211.512 215.91 210.772 213.405C209.498 209.434 208.175 205.474 206.522 201.507C206.168 200.653 205.795 199.8 205.404 198.947H205.572C207.833 203.742 209.516 208.541 211.127 213.334C213.94 222.568 216.476 231.806 224.688 241.045L224.808 241.175H224.466C180.095 241.195 135.694 241.215 91.2628 241.234H90.7696C90.82 241.17 90.874 241.11 90.928 241.045Z" fill="#B0C2E4" />
            <path d="M116.272 75.7335C116.272 74.8744 116.969 74.178 117.828 74.178H172.717C173.576 74.178 174.272 74.8744 174.272 75.7335V75.7335C174.272 76.5926 173.576 77.2891 172.717 77.2891H117.828C116.969 77.2891 116.272 76.5926 116.272 75.7335V75.7335Z" fill="#9DB2D9" />
            <path d="M116.272 80.9187C116.272 80.0596 116.969 79.3632 117.828 79.3632H145.272H172.717C173.576 79.3632 174.272 80.0596 174.272 80.9187V80.9187C174.272 81.7778 173.576 82.4743 172.717 82.4743H117.828C116.969 82.4743 116.272 81.7778 116.272 80.9187V80.9187Z" fill="#9DB2D9" />
            <path d="M116.272 86.6224C116.272 85.7633 116.969 85.0669 117.828 85.0669H172.717C173.576 85.0669 174.272 85.7633 174.272 86.6224V86.6224C174.272 87.4815 173.576 88.178 172.717 88.178H117.828C116.969 88.178 116.272 87.4815 116.272 86.6224V86.6224Z" fill="#9DB2D9" />
        </svg>

    );
};

export default EmptyPrinter;
