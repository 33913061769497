import React, { useEffect } from "react";
import Input from "../common/Input";
import { Button, IconButton, Tooltip } from "@material-tailwind/react";
import { generateCode } from "../../utils/utils";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
const AddGiftCard = ({
  setOpenGiftCardModal,
  formik,
  selectedIndex,
  handleGiftCardClose,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        type: "giftcard",
        gift_card_code:
          formik.values.orderItems[selectedIndex]?.gift_card_code || "",
        gift_card_amount:
          formik.values.orderItems[selectedIndex]?.gift_card_amount || "",
        qty: 1,
        discount: 0,
        grand_total: 0,
        discount_percentage: 0,
      }}
      validationSchema={Yup.object().shape({
        gift_card_code: Yup.string()
          .required("Please enter a valid gift card code")
          .min(8, "Gift card code minimum 8 characters long")
          .max(16, "Gift card code maximum 16 characters long"),
        gift_card_amount: Yup.number()
          .min(10, "Amount must be between $10 to $1000")
          .max(1000, "Amount must be between $10 to $1000")
          .required("Please enter a valid amount"),
      })}
      onSubmit={(values, { resetForm }) => {
        values.gift_card_amount = parseFloat(values.gift_card_amount).toFixed(
          2
        );
        console.log({ values });
        const newArr = [...formik.values.orderItems];

        if (selectedIndex !== null) {
          // Update the existing gift card
          newArr[selectedIndex] = values;
        } else {
          // Add a new gift card to the array
          newArr.push(values);
        }
        formik.setFieldValue("orderItems", newArr);
        setOpenGiftCardModal(false);
        resetForm();
      }}
    >
      {({
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        isValid,
        dirty,
      }) => {
        return (
          <Form className="flex flex-col gap-y-3" onSubmit={handleSubmit}>
            <h3 className="text-lg font-semibold capitalize">
              {selectedIndex !== null
                ? t("Edit Gift Card")
                : t("Gift Card Number")}
            </h3>
            <div className="flex flex-col gap-y-4">
              {/* {console.log(values, "gift card values")} */}
              <div>
                <Input
                  type={"text"}
                  placeholder={t("Enter Gift Card Number")}
                  className={
                    "placeholder:!font-medium placeholder:text-sm bg-secondary-50"
                  }
                  name="gift_card_code"
                  value={values.gift_card_code}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  iconEnd={
                    <IconButton
                      size="sm"
                      variant="text"
                      className="-mr-2.5"
                      onClick={() => {
                        const code = generateCode(8);
                        setFieldValue("gift_card_code", code);
                      }}
                    >
                      <FontAwesomeIcon icon={faRotateRight} fontSize={16} />
                    </IconButton>
                  }
                />
                <ErrorMessage
                  name="gift_card_code"
                  component={"p"}
                  className="text-sm text-danger"
                />
              </div>
              <div>
                <h3 className="pb-3 text-lg font-semibold capitalize">
                  {selectedIndex !== null
                    ? t("Edit Gift Card Amount")
                    : t("Gift Card Amount")}
                </h3>
                <Input
                  type={"number"}
                  placeholder={t("Enter Gift Card Amount")}
                  className={
                    "placeholder:!font-medium placeholder:text-sm bg-secondary-50"
                  }
                  name="gift_card_amount"
                  value={values.gift_card_amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="gift_card_amount"
                  component={"p"}
                  className="my-1 text-sm text-danger"
                />
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-4">
              <Button
                type="reset"
                size="sm"
                onClick={() => {
                  handleReset();
                  setFieldValue("gift_card_code", "");
                  setFieldValue("gift_card_amount", "");

                  handleGiftCardClose();
                }}
                className="px-5 py-2.5 border border-[#CBD4E1] shadow-none text-gray-700 rounded-lg text-sm font-semibold bg-white capitalize"
              >
                {t("Cancel")}
              </Button>
              <Button
                size="sm"
                type="submit"
                disabled={!(isValid && dirty)}
                className="border border-secondary-200 flex items-center justify-center xl:text-sm font-semibold bg-primary-400 !text-white border-primary-400 xl:rounded-theme rounded-base xl:px-5 lg:px-4 px-3.5 xl:py-3 py-2  text-nowrap"
              >
                {selectedIndex !== null
                  ? t("Edit to Order")
                  : t("Add to Order")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddGiftCard;
