import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import { deviceState } from "./state";
import { profileAction } from "../settings.fetch";

/* -------------------------------------------------------------------------- */
/*                           get all device request                           */
/* -------------------------------------------------------------------------- */
export const getAllDeviceRequest = () => async (dispatch) => {
  try {
    dispatch(deviceState({ key: "getAllDeviceData.loading", value: true }));
    const response = await AxiosRequest.get(`/user/setting`);
    const data = await response.data.data;
    if (data) {
      dispatch(deviceState({ key: "getAllDeviceData.data", value: data }));
      dispatch(deviceState({ key: "getAllDeviceData.loading", value: false }));
    }
    return response;
  } catch (error) {
    toast.error(error);
    dispatch(deviceState({ key: "getAllDeviceData.loading", value: false }));
    dispatch(deviceState({ key: "getAllDeviceData.error", value: error }));
  }
};

/* -------------------------------------------------------------------------- */
/*                           update all device request                           */
/* -------------------------------------------------------------------------- */
export const updateAllDeviceRequest =
  ({ id, body }) =>
    async (dispatch) => {
      try {
        dispatch(
          deviceState({ key: "updateAllDeviceData.loading", value: true })
        );
        const response = await AxiosRequest.put(
          `/user/update-setting/${id}`,
          body
        );
        const data = await response.data.data;
        if (data) {
          toast.success(response.data.message);
          localStorage.setItem("user_org_setting", JSON.stringify(data?.updatedData));
          dispatch(deviceState({ key: "updateAllDeviceData.data", value: data }));
          dispatch(
            deviceState({ key: "updateAllDeviceData.loading", value: false })
          );
          dispatch(getAllDeviceRequest())
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(
          deviceState({ key: "updateAllDeviceData.loading", value: false })
        );
        dispatch(deviceState({ key: "updateAllDeviceData.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                           get this device request                           */
/* -------------------------------------------------------------------------- */
export const getThisDeviceRequest = () => async (dispatch) => {
  try {
    dispatch(deviceState({ key: "getThisDeviceData.loading", value: true }));
    const response = await AxiosRequest.get(`/user/device-setting`);
    const data = await response.data.data;
    if (data) {
      dispatch(deviceState({ key: "getThisDeviceData.data", value: data }));
      dispatch(deviceState({ key: "getThisDeviceData.loading", value: false }));
    }
    return response;
  } catch (error) {
    toast.error(error);
    dispatch(deviceState({ key: "getThisDeviceData.loading", value: false }));
    dispatch(deviceState({ key: "getThisDeviceData.error", value: error }));
  }
};

/* -------------------------------------------------------------------------- */
/*                           update this device request                           */
/* -------------------------------------------------------------------------- */
export const updateThisDeviceRequest =
  ({ id, body }) =>
    async (dispatch) => {
      try {
        dispatch(
          deviceState({ key: "updateThisDeviceData.loading", value: true })
        );
        const response = await AxiosRequest.put(
          `/user/update-device-setting/${id}`,
          body
        );
        const data = await response.data.data;
        if (data) {
          toast.success(response.data.message);
          dispatch(
            deviceState({ key: "updateThisDeviceData.data", value: data })
          );
          dispatch(profileAction())
          dispatch(
            deviceState({ key: "updateThisDeviceData.loading", value: false })
          );
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(
          deviceState({ key: "updateThisDeviceData.loading", value: false })
        );
        dispatch(
          deviceState({ key: "updateThisDeviceData.error", value: error })
        );
      }
    };
