import React, { Fragment, useLayoutEffect } from "react";
import Layout from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleProduct } from "../../store/inventory/product/actions";
import {
  Button,
  Chip,
  IconButton,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { StarBrandSvg } from "../../components/svgs";
import Loader from "../../components/common/Loader";
import { formatCurrencyWithNumberValue } from "../../utils/utils";
import { useTranslation } from "react-i18next";

const ViewProductDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading, productData } = useSelector((store) => ({
    isLoading: store?.product?.singleProduct?.loading,
    productData: store?.product?.singleProduct?.data,
  }));

  const [activeTab, setActiveTab] = React.useState(0);
  const tabValue = [
    {
      label: t("Prices/code"),
      data: () => (
        <div className="grid grid-cols-2 gap-6">
          <div className="flex flex-col 2xl:gap-y-3 gap-y-2">
            <label
              htmlFor=""
              className="text-sm font-medium text-secondary-500 2xl:text-base"
            >
              {t("Cost Price")}
            </label>
            <div className="bg-secondary-50 2xl:text-lg text-sm text-info-500 font-medium rounded-theme h-12 flex items-center px-3.5">
              <p>
                {formatCurrencyWithNumberValue(productData?.cost_price || 0)}
              </p>
            </div>
          </div>
          <div className="flex flex-col 2xl:gap-y-3 gap-y-2">
            <label
              htmlFor=""
              className="text-sm font-medium text-secondary-500 2xl:text-base"
            >
              {t("Selling Price")}
            </label>
            <div className="bg-secondary-50 2xl:text-lg text-sm text-info-500 font-medium rounded-theme h-12 flex items-center px-3.5">
              <p>{formatCurrencyWithNumberValue(productData?.selling_price || 0)}</p>
            </div>
          </div>
          {/* <div className="flex flex-col 2xl:gap-y-3 gap-y-2">
            <label
              htmlFor=""
              className="text-sm font-semibold text-secondary-500 2xl:text-lg"
            >
              {t("Cost Price")}
            </label>
            <div className="bg-secondary-50 text-lg text-info-500 font-medium rounded-theme h-12 flex items-center px-3.5">
              <p>
                {formatCurrencyWithNumberValue(productData?.cost_price || 0)}
              </p>
            </div>
          </div> */}

          <div className="flex flex-col gap-y-3">
            <label
              htmlFor=""
              className="text-lg font-medium text-secondary-500 2xl:text-base"
            >
              {t("Scan Code")}
            </label>
            <div className="bg-secondary-50 2xl:text-lg text-sm text-info-500 font-medium rounded-theme h-12 flex items-center px-3.5">
              <p>{productData?.scan_code}</p>
            </div>
          </div>
          <div className="flex flex-col 2xl:gap-y-3 gap-y-2">
            <label
              htmlFor=""
              className="text-lg font-medium text-secondary-500 2xl:text-base"
            >
              {t("Internal Code")}
            </label>
            <div className="bg-secondary-50 2xl:text-lg text-sm text-info-500 font-medium rounded-theme h-12 flex items-center px-3.5">
              <p>{productData?.internal_code}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: t("tags"),
      data: () => (
        <div className="flex flex-col 2xl:gap-y-6 gap-y-4">
          <div className="flex flex-col 2xl:gap-y-3 gap-y-2">
            <label
              htmlFor=""
              className="text-sm font-semibold capitalize text-secondary-500 2xl:text-lg"
            >
              {t("categories Tags")}
            </label>
            <div className="flex flex-wrap items-center gap-3">
              {productData?.categories_tag?.map((item, index) => (
                <Chip
                  key={index}
                  size="lg"
                  value={item.name}
                  className="py-3 text-sm font-medium capitalize bg-secondary-50 rounded-theme text-info-400 2xl:text-lg"
                />
              ))}
            </div>
          </div>
          <hr />
          <div className="flex flex-col 2xl:gap-y-3 gap-y-2">
            <label
              htmlFor=""
              className="text-sm font-semibold capitalize text-secondary-500 2xl:text-lg"
            >
              {t("vendors Tags")}
            </label>
            <div className="flex flex-wrap items-center gap-3">
              <Chip
                size="lg"
                value={productData?.vendor_id?.name}
                className="py-3 text-sm font-medium capitalize bg-secondary-50 rounded-theme text-info-400 2xl:text-lg"
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      label: t("variant"),
      data: () => (
        <div className="flex flex-col gap-4">
          <div className="grid items-center grid-cols-12 gap-4 ">
            <h3 className="col-span-3 text-sm font-semibold xl:col-span-6 text-secondary-400 2xl:text-base">
              {t("Variant")}
            </h3>
            <h3 className="col-span-3 text-sm font-semibold text-secondary-400 2xl:text-base xl:col-span-2 ">
              {t("Price")}
            </h3>
            <h3 className="col-span-3 text-sm font-semibold text-secondary-400 2xl:text-base xl:col-span-2 ">
              {t("Cost Price")}
            </h3>
            <h3 className="col-span-3 text-sm font-semibold text-secondary-400 2xl:text-base xl:col-span-2 ">
              {t("Stock")}
            </h3>
          </div>
          <hr />
          <div className="grid items-center grid-cols-12 gap-4">
            {productData?.variants_possibilities?.map((item, index) => (
              <Fragment>
                <h3 className="col-span-3 text-sm font-semibold xl:col-span-6 text-info-400 2xl:text-base">
                  {item?.name}
                </h3>
                <h3 className="col-span-3 text-sm font-semibold text-info-400 2xl:text-base xl:col-span-2 ">
                  {formatCurrencyWithNumberValue(item?.price)}
                </h3>
                <h3 className="col-span-3 text-sm font-semibold text-info-400 2xl:text-base xl:col-span-2 ">
                  {formatCurrencyWithNumberValue(item.cost_price)}
                </h3>
                <h3 className="col-span-3 text-sm font-semibold text-info-400 2xl:text-base xl:col-span-2 ">
                  {item.stock}
                </h3>
                {index < productData?.variants_possibilities?.length - 1 && (
                  <hr className="col-span-12" />
                )}
              </Fragment>
            ))}
          </div>
        </div>
      ),
    },
  ];

  useLayoutEffect(() => {
    if (id) {
      dispatch(getSingleProduct({ productId: id }));
    }
  }, [dispatch, id]);
  return (
    <Layout>
      {isLoading && (
        <div className="absolute z-50 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
          <Loader />
        </div>
      )}
      <div className="xl:h-[calc(100vh-126px)] h-[calc(100vh-100px)] overflow-y-auto">
        <div className="bg-white w-full xl:p-5 lg:p-4 p-3.5 rounded-theme mb-4 flex justify-between items-center">
          <div className="flex gap-2.5 items-center">
            <IconButton
              type="button"
              variant="text"
              size="sm"
              className="-ml-2 "
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} fontSize={18} />
            </IconButton>
            <p className="text-base font-semibold lg:text-lg 2xl:font-bold text-info-500 2xl:text-xl">
              {t("Product Details")}
            </p>
          </div>
          <Button
            type="button"
            variant="text"
            size="sm"
            className="text-base font-semibold capitalize text-primary-400"
            onClick={() => navigate(`/inventory/items/form?id=${id}`)}
          >
            {t("Edit Product")}
          </Button>
        </div>
        <div className="flex w-full 2xl:gap-x-6 gap-x-4">
          <div className="flex flex-col w-full grow gap-y-5">
            <div className="flex flex-col p-5 bg-white gap-y-5 rounded-theme">
              <div className="inline-flex">
                <Chip
                  className="bg-primary-150 text-primary-400"
                  variant="ghost"
                  value={
                    <div className="flex items-center gap-x-3">
                      <StarBrandSvg />
                      {productData?.brand_id?.name && (
                        <p className="font-medium capitalize text-primary-400 2xl:text-lg text-sm_18">
                          {productData?.brand_id?.name}
                        </p>
                      )}
                    </div>
                  }
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <label className="block text-sm font-medium 2xl:text-base text-secondary-500">
                  {t("Product Name")}
                </label>
                <div className="bg-secondary-50 2xl:text-base text-base_24 text-info-500 font-medium rounded-theme p-3.5">
                  <p>{productData?.product_name}</p>
                </div>
              </div>
              {productData?.description && (
                <div className="flex flex-col gap-y-2">
                  <label className="block text-sm font-medium 2xl:text-base text-secondary-500">
                    {t("Description")}
                  </label>
                  <div className="bg-secondary-50 2xl:text-lg text-sm_18 text-info-500 font-medium rounded-theme p-3.5">
                    <p className="text-base">{productData?.description}</p>
                  </div>
                </div>
              )}
            </div>
            <Tabs value={activeTab}>
              <TabsHeader
                className="p-0 bg-transparent border-b rounded-none border-secondary-200"
                indicatorProps={{
                  className:
                    "bg-transparent border-b-2 border-primary-400 shadow-none rounded-none",
                }}
              >
                {productData?.variants_possibilities?.length > 0
                  ? tabValue.map((item, index) => (
                    <Tab
                      key={index}
                      value={index}
                      onClick={() => setActiveTab(index)}
                      className={`min-w-32 w-32 max-w-64 ${activeTab === index
                        ? "text-primary-400 font-semibold capitalize"
                        : "text-secondary-500 font-medium capitalize"
                        }
                    `}
                    >
                      {item.label}
                    </Tab>
                  ))
                  : tabValue.slice(0, 2).map((item, index) => (
                    <Tab
                      key={index}
                      value={index}
                      onClick={() => setActiveTab(index)}
                      className={`min-w-32 w-32 max-w-64 ${activeTab === index
                        ? "text-primary-400 font-semibold capitalize"
                        : "text-secondary-500 font-medium capitalize"
                        }
                    `}
                    >
                      {item.label}
                    </Tab>
                  ))}
              </TabsHeader>
              <TabsBody className="mt-5 bg-white rounded-theme">
                {productData?.variants_possibilities?.length > 0
                  ? tabValue.map((item, index) => (
                    <TabPanel key={index} value={index} className="">
                      {item.data()}
                    </TabPanel>
                  ))
                  : tabValue.slice(0, 2).map((item, index) => (
                    <TabPanel key={index} value={index} className="">
                      {item.data()}
                    </TabPanel>
                  ))}
              </TabsBody>
            </Tabs>
          </div>
          <div className="2xl:w-[400px] w-52 flex-shrink-0">
            <div className="flex items-center justify-center w-full mb-6 overflow-hidden 2xl:h-96 h-52 rounded-theme">
              <img
                src={productData?.image}
                alt="user-profile"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ViewProductDetails;
