import { faArrowLeft, faGift } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, IconButton } from "@material-tailwind/react";
import moment from "moment";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "../../assets/icon/close.svg";
import Loader from "../../components/common/Loader";
import { NewOrderIcon, OrderReturnIcon, PrintReceiptIcon, SendEmailIcon, ViewOrderIcon } from "../../components/svgs";
import DeleteIconSvg from "../../components/svgs/DeleteIconSvg";
import Layout from "../../layout";
import { getOrderDetailRequest, sendPrinterRequest } from "../../store/order/action";
import {
  formatCurrencyWithNumberValue,
  getUserOrgLocationSetting,
} from "../../utils/utils";
import Input from "../../components/common/Input";
import * as Yup from "yup";
import { useFormik } from "formik";
import { values } from "lodash";
import SingleReturnOrder from "../../components/order/SingleReturnOrder";
import { toast } from "react-toastify";
import { discountType } from "../../utils/enums";
import {
  addToCartRequest,
  getRemoveHoldOrderRequest,
  getSendReceiptRequest,
} from "../../store/order/actions";
import Modal from "../../components/common/Modal";
import SidePanel from "../../components/common/SidePanel";
import OrderReceipt from "../../components/order/OrderReceipt";
import { useQuery } from "@tanstack/react-query";

const ViewOrderDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order_id = useParams();
  const orderId = order_id?.id;

  const {
    isLoading,
    totalOrderData,
    customerData,
    orderData,
    orderItems,
    returnOrderItems,
    payment,
    returnPayments,
    completeHoldOrderLoading,
    deleteHoldOrderLoading,
    defaultOrgLocation,
    getDeviceData,
    organization,
    sendReceiptLoading,
    lane_no,
    org_name,
    footer,
  } = useSelector((store) => ({
    isLoading: store?.order?.orderDetail?.loading,
    totalOrderData: store?.order?.orderDetail?.data,
    customerData: store?.order?.orderDetail?.data?.customer,
    orderData: store?.order?.orderDetail?.data?.order,
    orderItems: store?.order?.orderDetail?.data?.orderItems,
    returnOrderItems: store?.order?.orderDetail?.data?.returnOrderItems,
    payment: store?.order?.orderDetail?.data?.payments,
    returnPayments: store?.order?.orderDetail?.data?.returnPayments,
    completeHoldOrderLoading: store?.order?.addToCart?.loading,
    deleteHoldOrderLoading: store?.order?.deleteHoldOrder?.loading,
    defaultOrgLocation: store?.setting?.defaultOrgLocation,
    getDeviceData: store?.device?.getAllDeviceData?.data,
    organization: store?.setting?.organization,
    sendReceiptLoading: store?.order?.sendReceiptDetails?.loading,
    lane_no: store?.setting?.user_device_setting?.lane_number,
    org_name: store?.setting?.organization?.name,
    footer: store?.setting?.organization?.footer
  }));

  // const { data: totalOrderData, isLoading } = useQuery({
  //   queryKey: ["singleOrderDetail"],
  //   queryFn: () => getOrderDetailRequest({ orderId }),
  //   staleTime: 5 * 60 * 1000,
  // });

  // const {
  //   order: orderData = null,
  //   customer: customerData = null,
  //   orderItems = [],
  //   returnOrderItems = [],
  //   payments: payment = [],
  //   returnPayments = [],
  // } = totalOrderData || {};

  console.log(totalOrderData, " single order data ");

  console.log(totalOrderData, "totalOrderDatatotalOrderData");
  const getOrgDetail = getUserOrgLocationSetting();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      returnOrder: {
        return_notes: "",
        customer_id: "",
        total_qty: 0,
        sub_total: 0,
        total_discount: 0,
        total_taxes: 0,
        grand_total: 0,
        redeemed_dollar: 0,
      },
      returnOrderItems: [],
    },
  });

  const { values, setFieldValue, handleChange, errors } = formik;

  const [openEditOrder, setOpenEditOrder] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [returnNotes, setReturnNotes] = useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState("");
  const [openOrderReceipt, setOpenOrderReceipt] = useState(false);

  // Calculate already payable amount with two decimal precision
  const alreadyPayableAmount = parseFloat(
    (totalOrderData?.returnPayments || [])
      .reduce((sum, payment) => sum + (parseFloat(payment?.amount) || 0), 0)
      .toFixed(2)
  );

  // const tax_details = useSelector(
  //   (store) => store?.setting?.defaultOrgLocation?.tax_details
  // );

  const totalTaxPercentage = (orderData?.tax_rates || [])?.reduce(
    (acc, t) => acc + t.tax_percentage,
    0
  );

  console.log(totalTaxPercentage, "totalTaxPercentage...........");

  // const completeHoldOrder = () => {
  //   const createCartData = {
  //     customer_id: customerData?._id,
  //     orderItems: orderItems.map((item) => {
  //       console.log(item, "orderItems...............");
  //       if (item.type === "inventory") {
  //         return {
  //           type: "inventory",
  //           inventory_id: item?.inventory_id?._id,
  //           sku: item?.sku,
  //           qty: item?.qty,
  //           selling_price: item?.selling_price,
  //           sub_total: item?.sub_total,
  //           discount_type: item?.discount_type,
  //           discount_percentage: item?.discount_percentage,
  //           discount: item?.discount,
  //           taxes: item?.taxes,
  //           grand_total: item?.grand_total,
  //         };
  //       } else {
  //         return {
  //           type: item?.type,
  //           gift_card_code: item.gift_card_code,
  //           gift_card_amount: Number(item.sub_total),
  //           qty: item?.qty,
  //           discount: item?.discount,
  //           grand_total: item.grand_total,
  //           discount_percentage: item?.discount_percentage,
  //         };
  //       }
  //     }),
  //   };
  //   dispatch(addToCartRequest({ body: createCartData, navigate, orderId }));
  // };

  const handleCheckboxChange = (e, index, item) => {
    const isChecked = e.target.checked;

    console.log(item, "items");

    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = isChecked
        ? [...prevSelectedItems, index]
        : prevSelectedItems.filter((i) => i !== index);

      // Check if the item is already present in the returnOrderItems array by SKU
      const isItemAlreadyAdded = values.returnOrderItems.find(
        (existingItem) => existingItem.sku === item.sku
      );

      // if checked and item is not in array.
      // if unchecked and item is into array.
      // if unchecked and item is into array but item has return qty.
      // if unchecked and remove the extra added qty of that item.

      if (isChecked && !isItemAlreadyAdded) {
        // Add item if it is not already present
        setFieldValue("returnOrderItems", [
          ...values.returnOrderItems,
          {
            type: "inventory",
            inventory_id: item?.inventory_id?._id,
            product_name: item?.inventory_id?.product_name,
            sku: item?.sku,
            qty: item?.qty,
            selling_price: item?.selling_price,
            sub_total: 0,
            discount_type: item?.discount_type,
            return_qty: item?.return_qty || 0,
            orderItemId: item?._id,
            discount_percentage: item?.discount_percentage,
            discount: 0,
            old_discount: item?.discount,
            taxes: 0,
            loyalty_point_use: item?.redeemed_dollar || 0,
            grand_total: item?.grand_total,
          },
        ]);
      } else if (!isChecked) {
        // Remove item if unchecked only if return_qty is 1 or less
        if (!item?.return_qty) {
          console.log("come at here 1");
          setFieldValue(
            "returnOrderItems",
            values.returnOrderItems.filter(
              (existingItem) => existingItem.sku !== item.sku
            )
          );
        } else if (item?.return_qty) {
          const updatedItems = values.returnOrderItems.map((existingItem) =>
            existingItem.sku === item.sku
              ? { ...existingItem, return_qty: item.return_qty }
              : existingItem
          );

          setFieldValue("returnOrderItems", updatedItems);
        }
      }

      return newSelectedItems;
    });
  };

  // if return_qty is greater than 1 then directly push Item in returnOrderItems array
  useLayoutEffect(() => {
    if (orderItems && orderItems.length > 0) {
      const itemsToReturn = orderItems
        .filter((item) => item?.return_qty > 0)
        .map((item) => {
          const returnItem = returnOrderItems?.find(
            (returnItem) => returnItem.sku === item.sku
          );

          console.log(returnItem, "returnItem...........");
          return {
            type: "inventory",
            inventory_id: item?.inventory_id?._id,
            product_name: item?.inventory_id?.product_name,
            sku: item?.sku,
            qty: item?.qty,
            selling_price: item?.selling_price,
            sub_total: 0,
            discount_type: item?.discount_type,
            return_qty: item?.return_qty || 0,
            orderItemId: item?._id,
            discount_percentage: item?.discount_percentage,
            payable_amount: 0,
            discount: 0,
            old_discount: item?.discount,
            taxes: item?.taxes,
            loyalty_point_use: item?.redeemed_dollar || 0,
            // If a match is found, use the grand_total from returnOrderItems, otherwise use from orderItems
            grand_total: returnItem
              ? returnItem.grand_total
              : item?.grand_total,
          };
        });

      setFieldValue("returnOrderItems", itemsToReturn);
    }
  }, [orderItems, returnOrderItems, setFieldValue, openEditOrder]);

  useLayoutEffect(() => {
    if (customerData && orderId) {
      formik.setFieldValue("returnOrder.customer_id", customerData?._id);
    }
  }, [customerData, orderId, orderItems]);

  useLayoutEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailRequest({ orderId }));
    }
  }, [orderId, dispatch]);

  useEffect(() => {
    setReturnNotes(totalOrderData?.returnOrder?.return_notes);
  }, [totalOrderData]);

  const requestBillPrint = () => {
    const { order, customer, orderItems, returnPayments, payments } = totalOrderData;

    const printerData = {
      org_location_id: defaultOrgLocation?._id,
      lane_no,
      location_name: defaultOrgLocation?.location_name,
      location_address: defaultOrgLocation?.location_address,
      org_name,
      footer,
      customer,
      order,
      orderItems,
      payments,
      receipt_type: 'BILL',
    }

    dispatch(sendPrinterRequest(printerData))
  }

  const requestGiftPrint = () => {
    const { order, customer, orderItems, returnPayments, payments } = totalOrderData;

    const printerData = {
      org_location_id: defaultOrgLocation?._id,
      lane_no,
      location_name: defaultOrgLocation?.location_name,
      location_address: defaultOrgLocation?.location_address,
      org_name,
      footer,
      customer,
      order,
      orderItems,
      payments,
      receipt_type: 'GIFT',
    }

    dispatch(sendPrinterRequest(printerData))
  }

  const sendEmailFn = () => {
    dispatch(getSendReceiptRequest(orderId))
  }

  return (
    <Layout>
      {isLoading && (
        <div className="absolute z-50 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
          <Loader />
        </div>
      )}
      <div>
        <div className="flex 2xl:gap-x-5 gap-x-2.5 overflow-x-hidden overflow-y-auto xl:h-[calc(100vh-119px)] h-[calc(100vh-100px)]">
          <div className="grow space-y-2.5">
            <div className="bg-white w-full lg:p-4 p-3.5 rounded-theme">
              <div className="flex items-center xl:pb-[30px] lg:pb-6 pb-5 gap-x-2 ">
                <IconButton
                  type="button"
                  variant="text"
                  className="flex-shrink-0"
                  onClick={() => navigate(-1)}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="text-lg 2xl:text-xl"
                  />
                </IconButton>
                <div className="bg-secondary-100 w-full rounded-theme 2xl:py-2.5 py-2 2xl:pl-5 pl-3 2xl:pr-8 pr-5 flex justify-between items-center">
                  <div className="flex items-center gap-4 xl:gap-10 lg:gap-6">
                    <p className="font-semibold text-info-700 2xl:text-lg text-sm_18">
                      {orderData?.order_no}
                    </p>
                    <p className="font-medium text-secondary-400 2xl:text-lg text-sm_18">
                      {moment(orderData?.order_date).format(
                        `${getOrgDetail?.date_format?.date_format} hh.mm A`
                      )}

                    </p>

                    <div className="flex flex-col">
                      <p>{orderData?.created_by?.username}</p>
                      <p className="text-secondary-400">{orderData?.created_by?.email}</p>
                    </div>
                  </div>
                  <div
                    className={`bg-success/15 2xl:text-base text-xs rounded-base font-bold xl:py-2.5 lg:py-2 py-1.5 xl:px-4 lg:px-3 px-2 capitalize  ${orderData?.order_status === "hold"
                      ? "text-yellow-900 bg-yellow-800/20"
                      : orderData?.order_status === "completed"
                        ? "text-success bg-success/20"
                        : orderData?.order_status === "return"
                          ? "text-danger bg-danger/30"
                          : ""
                      } px-3 py-1 rounded-3xl`}
                  >
                    {orderData?.order_status === "return"
                      ? t("returned")
                      : t(orderData?.order_status)}
                  </div>
                </div>
              </div>
              <p className="pb-3 font-semibold 2xl:pb-5 2xl:text-lg text-sm_18 text-info-500">
                {t("Customer Details")}
              </p>
              {/* customer detail  */}
              <div className="grid items-center grid-cols-2">
                <div className="border-r border-secondary-200/60">
                  <div>
                    <p className="pb-2 text-xs font-semibold 2xl:text-base text-secondary-500">
                      {t("Customer Name")}
                    </p>
                    <p className="font-medium capitalize text-info-500 2xl:text-lg text-sm_18">
                      {t(
                        customerData?.first_name + " " + customerData?.last_name
                      )}
                    </p>
                  </div>
                </div>
                <div className="pl-[30px]">
                  <div>
                    <p className="pb-2 text-xs font-semibold 2xl:text-base text-secondary-500">
                      {t("Contact Information")}
                    </p>
                    <p className="font-medium text-info-500 2xl:text-lg text-sm_18">
                      {customerData?.code + " " + customerData?.phone}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* product table */}

            <p className="pt-3 font-semibold xl:text-lg lg:text-base text-sm_18 text-info-500">
              {t("Items Details")}
            </p>
            {console.log(orderData, "orderData")}
            {orderData?.order_status === "hold" ? (
              <div className="bg-white w-full xl:p-5 lg:p-4 p-3.5 rounded-theme max-h-[calc(100vh-340px)]">
                <div className="overflow-y-auto max-h-[calc(100vh-380px)]">
                  <table className="relative min-w-full ">
                    <thead className="sticky top-0 z-10 bg-white">
                      <tr className="">
                        <th className="w-2/5 py-6 pr-4 text-left xl:w-2/4">
                          {t("Product Details")}
                        </th>
                        <th className="py-5 pr-4 text-center">{t("SKU")}</th>
                        {/* <th className="py-5 pr-4 text-center">Discount</th> */}
                        <th className="py-5 pr-4 text-center">
                          {t("Price / Qty")}
                        </th>
                        <th className="py-5 pr-4 text-center">{t("Total")}</th>
                      </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                      {orderItems?.map((items, index) => (
                        <tr
                          className={`${orderItems?.length - 1 !== index ? "border-b-2" : ""
                            } border-secondary-500/10`}
                          key={index}
                        >
                          <td className="flex gap-2 py-5 pr-4 xl:gap-4">
                            <div className="xl:h-16 xl:w-16 w-10 h-10 min-w-[40px] flex justify-center items-center  overflow-hidden rounded-theme">
                              {items?.inventory_id?.image ? (
                                <img
                                  src={items?.inventory_id?.image}
                                  alt="product img"
                                  className="object-cover w-full h-full"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faGift}
                                  fontSize={30}
                                  className="text-primary-400"
                                />
                              )}
                            </div>
                            <div>
                              <p className="pb-2 text-xs font-medium capitalize text-info-500 xl:text-base lg:text-sm_18">
                                {items?.inventory_id?.product_name ||
                                  items?.gift_card_id?.gift_code}
                              </p>
                              {items?.inventory_id?.variants_possibilities
                                ?.length > 0 && (
                                  <p className="pb-2 text-xs font-medium capitalize text-secondary-400 2xl:text-base">
                                    {items?.inventory_id?.variants_possibilities?.find(
                                      (val) => val?.sku === items?.sku
                                    )?.name || "Variant not available"}
                                  </p>
                                )}
                            </div>
                          </td>
                          <td className="py-5 pr-4 text-xs font-medium text-center text-secondary-400 xl:text-base lg:text-sm_18">
                            {items?.sku || "-"}
                          </td>
                          {/* <td className="py-5 pr-4 text-xs font-medium text-center text-secondary-400 xl:text-base lg:text-sm_18">
                        {items?.discount_percentage || "0" + " " + "%"}
                      </td> */}
                          <td className="py-5 pr-4 font-medium text-center text-info-500 xl:text-lg lg:text-base text-sm_18">
                            <div className="2xl:col-span-1 col-span-1 flex xl:gap-2.5 gap-1.5 items-center justify-center">
                              <p className="text-xs font-medium text-secondary-400 xl:text-base lg:text-sm_18">
                                {formatCurrencyWithNumberValue(
                                  items?.selling_price ||
                                  items?.sub_total ||
                                  "0"
                                )}
                              </p>
                              <img
                                src={CloseIcon}
                                alt="close"
                                className="xl:h-[14px] xl:w-[14px] w-2.5 h-2.5"
                              />
                              <p className="text-xs font-medium text-secondary-400 xl:text-base lg:text-sm_18">
                                {items?.qty}
                              </p>
                            </div>
                          </td>
                          <td className="py-5 pr-4 font-medium text-center text-info-500 xl:text-lg lg:text-base text-sm_18">
                            {formatCurrencyWithNumberValue(items?.grand_total)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="bg-white w-full xl:p-5 lg:p-4 p-3.5 rounded-theme max-h-[calc(100vh-340px)]">
                <div className="overflow-y-auto max-h-[calc(100vh-380px)]">
                  <table className="relative min-w-full border-collapse border-secondary-500/10">
                    <thead className="sticky top-0 z-10 text-sm bg-white 2xl:text-lg">
                      <tr className="">
                        <th className="w-0 p-0"></th>
                        <th className="w-1/3 py-3 pr-3 text-left 2xl:py-5 2xl:pr-4 xl:w-2/5 whitespace-nowrap">
                          {t("Product Details")}
                        </th>
                        <th className="py-3 pr-3 text-center 2xl:py-5 2xl:pr-4 whitespace-nowrap">
                          {t("SKU")}
                        </th>
                        {/* <th className="py-3 pr-3 text-center 2xl:py-5 2xl:pr-4">Discount</th> */}
                        <th className="py-3 pr-3 text-center 2xl:py-5 2xl:pr-4 whitespace-nowrap">
                          {t("Price / Qty")}
                        </th>
                        <th className="py-3 pr-3 text-center 2xl:py-5 2xl:pr-4 whitespace-nowrap">
                          {t("Return Qty")}
                        </th>
                        <th className="py-3 pr-3 text-center 2xl:py-5 2xl:pr-4 whitespace-nowrap">
                          {t("Price")}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                      {orderItems?.map((items, index) => (
                        <SingleReturnOrder
                          key={index}
                          items={items}
                          index={index}
                          selectedItems={selectedItems}
                          openEditOrder={openEditOrder}
                          handleCheckboxChange={handleCheckboxChange}
                          totalTaxPercentage={totalTaxPercentage}
                          orderData={orderData}
                          totalOrderData={totalOrderData}
                          formik={formik}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="2xl:w-[400px] w-72">
            <div className="bg-white w-full 2xl:p-5 p-3.5 rounded-theme">
              {openEditOrder && (
                <>
                  <p className="pb-3 font-semibold 2xl:text-lg text-sm_18 text-info-500">
                    Notes
                  </p>
                  <div className="relative mb-5 text-sm font-medium 2xl:p-2 bg-secondary-100 rounded-base text-info-500">
                    {/* {customerData?.notes} */}

                    <textarea
                      className="w-full p-2 bg-transparent border-none outline-none focus:ring-0 focus:outline-none placeholder-secondary-400 focus:shadow-none"
                      name="Notes"
                      placeholder="Enter Reason"
                      value={returnNotes || ""}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setReturnNotes(newValue); // Update the local state
                        setFieldValue("returnOrder.return_notes", newValue); // Update Formik state
                      }}
                    />
                  </div>
                </>
              )}
              {((orderData?.order_status === "return" &&
                totalOrderData?.returnOrder?.return_notes.length > 0) ||
                orderData?.order_status === "hold") && (
                  <div>
                    <p className="pb-3 font-semibold 2xl:text-lg text-sm_18 text-info-500">
                      {t("Reason")}
                    </p>
                    <div className="resize-none lg:p-4 p-3 bg-secondary-100 rounded-base text-info-500  2xl:text-base_30 text-xs font-medium 2xl:mb-6 mb-4 w-[360px] h-auto ">
                      {orderData?.order_status === "return"
                        ? t(totalOrderData?.returnOrder?.return_notes)
                        : orderData?.order_status === "hold"
                          ? t(orderData?.hold_reason)
                          : ""}
                    </div>
                  </div>
                )}

              {openEditOrder ? (
                <div>
                  <div className="flex justify-between">
                    <p className="pb-3 font-semibold 2xl:text-lg text-sm_18 text-info-500">
                      {t("Return Summary ")}
                    </p>

                    {/* {orderItems?.filter(
                      (item) => item?.qty !== item?.return_qty
                    )?.length > 0 && (
                        <p
                          onClick={() => setOpenOrderReceipt(true)}
                          className="pb-3 text-sm font-semibold cursor-pointer xl:text-base lg:text-base text-secondary-400"
                        >
                          {t("View Edit Receipt")}
                        </p>
                      )} */}
                  </div>
                  <div className="bg-secondary-100 flex flex-col gap-y-2.5 p-4 rounded-base lg:mb-4 mb-5">
                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="capitalize text-secondary-400">
                        {t(`items (${values.returnOrderItems?.length})`)}
                      </p>
                      <p className="font-medium text-info-500">
                        {formatCurrencyWithNumberValue(
                          values.returnOrder?.sub_total
                        )}
                      </p>
                    </div>
                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="capitalize text-secondary-400">
                        {t("Discount")}:
                      </p>
                      <p className="font-medium text-info-500">
                        {formatCurrencyWithNumberValue(
                          values.returnOrder?.total_discount || "0"
                        )}
                      </p>
                    </div>
                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="capitalize text-secondary-400">
                        {t("sub total")}:
                      </p>
                      <p className="font-medium text-info-500">
                        {formatCurrencyWithNumberValue(
                          values.returnOrder?.sub_total -
                          values.returnOrder?.total_discount
                        )}
                      </p>
                    </div>

                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="capitalize text-secondary-400">
                        {t(`Tax (${totalTaxPercentage}%)`)}:
                      </p>
                      <p className="font-medium text-info-500">
                        {formatCurrencyWithNumberValue(
                          values.returnOrder?.total_taxes
                        )}
                      </p>
                    </div>
                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="capitalize text-secondary-400">
                        {t("Loyalty Points")}:
                      </p>
                      <p className="font-medium text-info-500">
                        {formatCurrencyWithNumberValue(
                          values.returnOrder?.redeemed_dollar || 0
                        )}
                      </p>
                    </div>
                    <hr className="border-dashed border-secondary-200" />

                    <div className="flex items-center justify-between">
                      <p className="font-bold 2xl:text-lg text-base_24 text-primary-400">
                        {t("Return Amount")}
                      </p>
                      <p className="font-bold 2xl:text-lg text-base_24 text-primary-400">
                        {formatCurrencyWithNumberValue(
                          values.returnOrder?.payable_amount || 0
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex justify-between">
                    <p className="pb-3 font-semibold xl:text-lg lg:text-base text-sm_18 text-info-500">
                      {t(
                        orderData?.order_status === "hold"
                          ? "Payment Hold"
                          : "Payment"
                      )}
                    </p>
                    {/* {orderItems?.filter(
                      (item) => item?.qty !== item?.return_qty
                    )?.length > 0 && (
                        <p
                          onClick={() => setOpenOrderReceipt(true)}
                          className="pb-3 text-sm font-semibold cursor-pointer xl:text-base lg:text-base text-secondary-400"
                        >
                          {t("View Receipt")}
                        </p>
                      )} */}
                  </div>

                  <div className="bg-secondary-100 flex flex-col gap-y-2.5 p-4 rounded-base lg:mb-4 mb-5">
                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="capitalize text-secondary-400">
                        {t("items")}
                      </p>
                      <p className="font-medium text-info-500">
                        {orderItems?.length + " " + t("(items)")}
                      </p>
                    </div>
                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="capitalize text-secondary-400">
                        {t("sub total")}:
                      </p>
                      <p className="font-medium text-info-500">
                        {formatCurrencyWithNumberValue(orderData?.sub_total)}
                      </p>
                    </div>
                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="capitalize text-secondary-400">
                        {t("Discount")}:
                      </p>
                      <p className="font-medium text-info-500">
                        {formatCurrencyWithNumberValue(
                          orderData?.total_discount || "0"
                        )}
                      </p>
                    </div>
                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="capitalize text-secondary-400">
                        {t(`Tax (${totalTaxPercentage}%)`)}:
                      </p>
                      <p className="font-medium text-info-500">
                        {formatCurrencyWithNumberValue(orderData?.total_taxes)}
                      </p>
                    </div>
                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="capitalize text-secondary-400">
                        {t("Loyalty Points")}:
                      </p>
                      <p className="font-medium text-info-500">
                        {formatCurrencyWithNumberValue(
                          orderData?.redeemed_dollar
                        )}
                      </p>
                    </div>

                    {totalOrderData?.payments?.map((payment, index) => {
                      return (
                        <div className="flex justify-between " key={index}>
                          <p className="capitalize text-secondary-400">
                            {payment?.payment_type}
                          </p>
                          <p className="font-medium text-info-500">
                            {formatCurrencyWithNumberValue(
                              payment?.amount || 0
                            )}
                          </p>
                        </div>
                      )
                    })}

                    {orderData?.order_status === "return" && (
                      <>
                        <hr className="border-dashed border-secondary-200" />

                        <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                          <p className="font-bold capitalize text-secondary-400">
                            {t("Return Amount")}
                          </p>
                        </div>

                        {returnPayments?.map((payment) => {
                          return (
                            <div className="flex justify-between ">
                              <p className="capitalize text-secondary-400">
                                {payment?.payment_type}
                              </p>
                              <p className="font-medium text-info-500">
                                {formatCurrencyWithNumberValue(
                                  payment?.amount || 0
                                )}
                              </p>
                            </div>
                          );
                        })}
                      </>
                    )}

                    <hr className="border-dashed border-secondary-200" />

                    <div className="flex items-center justify-between 2xl:text-base text-sm_18">
                      <p className="font-bold 2xl:text-lg text-base_24 text-primary-400">
                        {t("Total Payable Amount")}
                      </p>
                      <p className="font-bold 2xl:text-lg text-base_24 text-primary-400">
                        {formatCurrencyWithNumberValue(orderData?.grand_total)}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* order detail/ hold */}
              <form onSubmit={formik.handleSubmit}>
                {orderData?.order_status === "hold" && (
                  <div className="flex flex-col gap-y-2">
                    <Button
                      type="button"
                      className="flex items-center justify-center text-sm capitalize border gap-x-2 rounded-theme bg-primary-400 border-primary-400"
                      onClick={() => navigate(`/register?q=${orderId}`)}
                    >
                      <ViewOrderIcon />
                      {t("View/Edit Order")}
                    </Button>

                    {/* <Button
                      loading={completeHoldOrderLoading}
                      disabled={completeHoldOrderLoading}
                      type="button"
                      className="flex items-center justify-center text-sm capitalize border gap-x-2 rounded-theme bg-success border-success"
                      onClick={completeHoldOrder}
                    >
                      {t("Proceed to pay")}
                    </Button> */}

                    <Button
                      type="button"
                      className="flex items-center justify-center text-sm capitalize bg-white border gap-x-2 rounded-theme border-danger text-danger"
                      onClick={() => setOpenDeleteConfirmation(true)}
                    >
                      <DeleteIconSvg />
                      {t("Remove Order")}
                    </Button>
                  </div>
                )}

                {/* order detail/ completed  */}
                {(orderData?.order_status === "completed" ||
                  orderData?.order_status === "return") && (
                    <div>
                      {/* {console.log(
                      values.returnOrder?.payable_amount,
                      "values.returnOrder?.payable_amount"
                    )}
                    {console.log(
                      values.returnOrder?.grand_total,
                      "values.returnOrder?.grand_total"
                    )}
                    {console.log(alreadyPayableAmount, "alreadyPayableAmount")} */}
                      <Button
                        onClick={() => {
                          setOpenEditOrder(false);
                          setSelectedItems([]);
                          setFieldValue("returnOrderItems", []);
                          setFieldValue("returnOrder", {
                            customer_id: "",
                            total_qty: 0,
                            sub_total: 0,
                            total_discount: 0,
                            total_taxes: 0,
                            grand_total: 0,
                            redeemed_dollar: 0,
                          });
                        }}
                        fullWidth
                        className={`flex items-center justify-center mb-2 text-sm font-semibold capitalize bg-white border gap-x-2 border-primary-400 rounded-theme text-primary-400 ${openEditOrder ? "" : "hidden"
                          }`}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        // type="submit"
                        onClick={() => {
                          const sanitizedFormData = {
                            alreadyPayableAmount: alreadyPayableAmount,
                            customerData: customerData,
                            returnOrder: formik?.values?.returnOrder,
                            returnOrderItems: formik.values?.returnOrderItems,
                          };
                          navigate(`/order/receipt/${orderId}`, {
                            state: { FormData: sanitizedFormData },
                          });
                        }}
                        className={`flex items-center justify-center mb-2 text-sm font-semibold capitalize bg-white border gap-x-2 border-primary-400 rounded-theme text-primary-400 ${openEditOrder ? "" : "hidden"
                          }`}
                        fullWidth
                        disabled={
                          !(
                            (
                              formik.isValid &&
                              formik.dirty &&
                              values.returnOrderItems.length > 0 &&
                              values.returnOrder.total_qty !== 0 &&
                              values.returnOrder?.grand_total !== 0 &&
                              (values.returnOrder?.payable_amount >
                                Number(alreadyPayableAmount || 0) ||
                                (values.returnOrder?.payable_amount === 0 &&
                                  values.returnOrder?.redeemed_dollar))
                            )
                            //     Number(alreadyPayableAmount || 0)) ||
                            // values.returnOrder?.grand_total ===
                            //   values.returnOrder?.redeemed_dollar
                          )
                        }
                      >
                        <OrderReturnIcon />
                        {t("order return")}
                      </Button>

                      <div className="flex flex-col justify-start ml-4 space-y-2">
                        <Button
                          type="button"
                          onClick={() => setOpenEditOrder(true)}
                          className={`flex items-center justify-center text-sm font-semibold capitalize bg-white border gap-x-2 border-primary-400 rounded-theme text-primary-400 ${openEditOrder ? "hidden" : ""
                            }`}
                          fullWidth
                        >
                          <OrderReturnIcon />
                          {t("Return")}
                        </Button>


                        {orderData?.order_status !== "return" &&
                          <>
                            {/* <Button
                              type="button"
                              className={`flex items-center justify-center text-sm font-semibold capitalize bg-white border gap-x-2 border-primary-400 rounded-theme text-primary-400 ${openEditOrder ? "hidden" : ""}`}
                              fullWidth
                              loading={sendReceiptLoading}
                              onClick={sendEmailFn}
                            >
                              <SendEmailIcon className="w-4 h-4" />{" "}
                              {t("Send Email")}
                            </Button> */}
                            <Button
                              type="button"
                              className={`flex items-center justify-center text-sm font-medium capitalize border rounded-theme gap-x-2.5 bg-primary-400 border-primary-400 px-4 py-2.5 ${openEditOrder ? "hidden" : ""}`}
                              fullWidth
                              onClick={() => requestBillPrint()}
                            // disabled={!contentRef?.current}
                            >
                              <PrintReceiptIcon className="w-4 h-4" />
                              {/* Adjust icon size if needed */}
                              {t("Print Bill Receipt")}
                            </Button>
                            <Button
                              type="button"
                              className={`flex items-center justify-center text-sm font-medium capitalize border rounded-theme gap-x-2.5 bg-primary-400 border-primary-400 px-4 py-2.5 ${openEditOrder ? "hidden" : ""}`}
                              fullWidth
                              onClick={() => requestGiftPrint()}
                            // disabled={!contentRef?.current}
                            >
                              <PrintReceiptIcon className="w-4 h-4" />
                              {/* Adjust icon size if needed */}
                              {t("Print Gift Receipt")}
                            </Button>
                          </>

                        }


                      </div>
                    </div>
                  )}
              </form>
            </div>
          </div>
        </div>

        {/* Delete Order Modal  */}
        {/* <div className="flex flex-col items-center justify-center h-screen">
            <div className="bg-white p-5 rounded-theme lg:max-w-[500px] max-w-[400px] w-full">
                <p className="pb-3 text-lg font-semibold xl:text-2xl lg:text-xl text-info-500">Delete Order</p>
                <p className="xl:pb-[60px] lg:pb-12 pb-10 xl:text-xl lg:text-lg text-base font-medium text-secondary-500">Are you sure to delete this order?</p>
                <div className="flex items-center justify-end gap-4 lg:gap-5">
                    <button className="text-danger border border-danger rounded-base font-bold xl:text-lg lg:text-base text-sm_18 xl:py-[14px] lg:py-3 py-2.5 xl:px-5 lg:px-4 px-3">No, Cancel</button>
                    <button className="text-white bg-primary-400 rounded-base font-semibold xl:text-lg lg:text-base text-sm_18 xl:py-[14px] lg:py-3 py-2.5 xl:px-5 lg:px-4 px-3">Yes, delete item</button>
                </div>
            </div>
        </div> */}
      </div >

      <Modal
        open={openDeleteConfirmation}
        handler={() => {
          setOpenDeleteConfirmation(false);
          // setSelectedProductId(null);
        }}
      >
        <div className="flex flex-col ">
          <p className="pb-3 text-lg font-semibold text-info-400">
            {t("Remove Order")}
          </p>
          <p className="text-base font-medium text-secondary-400 pb-7">
            {t("Are you sure to Remove this order ?")}
          </p>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold capitalize border border-primary-400 bg-primary-400"
              onClick={() => {
                setOpenDeleteConfirmation(false);
              }}
            >
              {t("No, Cancel")}
            </Button>
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold text-red-500 capitalize bg-white border border-red-500"
              loading={deleteHoldOrderLoading}
              disabled={deleteHoldOrderLoading}
              onClick={() =>
                dispatch(
                  getRemoveHoldOrderRequest({
                    order_id: orderId,
                    navigate,
                  })
                )
              }
            >
              {t("Yes, remove order")}
            </Button>
          </div>
        </div>
      </Modal>

      {/* <SidePanel
        open={openOrderReceipt}
        handler={() => setOpenOrderReceipt(false)}
        width="w-[450px]"
      >
        {openOrderReceipt && (
          <OrderReceipt
            totalOrderData={totalOrderData || []}
            totalTaxPercentage={totalTaxPercentage}
            openOrderReceipt={openOrderReceipt}
            handler={() => setOpenOrderReceipt(false)}
            orderId={orderId}
            formik={formik}
          />
        )}
      </SidePanel> */}
    </Layout >
  );
};

export default ViewOrderDetails;
