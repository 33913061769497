import AxiosRequest from "../../AxiosRequest";



// get order details 
export const getOrderDetailRequest = async ({ orderId }) => {
    const response = await AxiosRequest.get(
        `/user/order/details/${orderId}`
    );

    console.log(response, "response.........................")
    return response.data.data;
};



// get order list 
export const getOrderListRequest = async ({
    page,
    limit,
    search = "",
    status = "",
    dateType = "",
    body,
    start_date,
    end_date,
    resource,
}) => {
    const totalResource = resource?.map(data => `resource[]=${data}`).join("&");

    let url;
    if (dateType !== "custom") {
        url = `/user/order/list?page=${page}&limit=${limit}&search=${search}&status=${status}&dateType=${dateType}&${totalResource}`;
    } else {
        url = `/user/order/list?page=${page}&limit=${limit}&search=${search}&status=${status}&dateType=${dateType}&${totalResource}&start_date=${start_date}&end_date=${end_date}`;
    }


    const response = await AxiosRequest.get(
        url
    );

    console.log(response, "response.........................")
    return response.data.data;
};