import { Link } from "react-router-dom";
import {
  inventoryNavigationTab,
  registerNavigationTab,
  reportNavigationTab,
  settingsNavigationTab,
} from "../../routes/navigation";
import { Fragment } from "react";
import { t } from "i18next";

export const RegisterNavTab = ({ location }) => {
  return registerNavigationTab.map((item, index) => (
    <Link
      to={item.path}
      key={index}
      className={`relative before:content-[''] before:absolute before:bottom-1 before:left-0 before:h-1 before:w-full before:rounded-t-theme  transition-all duration-500 py-5 capitalize ${location.pathname === item.path ? "before:bg-primary-400" : ""
        }`}
    >
      <div className="flex items-center gap-2">
        {item.icon({ location })}
        <div
          className={`lg:text-base text-sm_18 ${location.pathname === item.path
            ? "text-info-400 font-bold"
            : "text-secondary-500"
            }`}
        >
          {item.title}
        </div>
      </div>
    </Link>
  ));
};

export const InventoryNavTab = ({ location }) => {
  return inventoryNavigationTab.map((item, index) => (
    <Link
      to={item.path}
      key={index}
      className={`relative before:content-[''] before:absolute before:bottom-1 before:left-0 before:h-1 before:w-full before:rounded-t-theme  transition-all duration-500 py-5 capitalize ${location.pathname.includes(item.key) ? "before:bg-primary-400" : ""
        }`}
    >
      <div className="flex items-center gap-2">
        {item.icon({ location, path: item.key })}
        <div
          className={`lg:text-base text-sm_18 ${location.pathname.includes(item.key)
            ? "text-info-400 font-bold"
            : "text-secondary-500"
            }`}
        >
          {t(item.title)}
        </div>
      </div>
    </Link>
  ));
};

export const SettingsNavTab = ({ location, userDetail }) => {
  return settingsNavigationTab.map((item, index) => (
    <Fragment key={index}>
      {item.permission.includes(userDetail?.role_id?.role) && (
        <Link
          to={item.path}
          className={`flex items-center justify-start p-3 min-w-[250px] text-sm capitalize rounded-none gap-x-4 transition-all duration-300 ${location.pathname.includes(item.key)
            ? 'bg-primary-150 text-primary-400' // Active state styling
            : 'text-gray-600 hover:bg-gray-100'
            }`}
        >
          <div className="flex items-center gap-2">
            {item.icon({ location, path: item.key })}
            <div
              className={`text-sm font-medium ${location.pathname.includes(item.key)
                ? 'font-semibold text-primary-400'
                : 'text-secondary-500'
                }`}
            >
              {t(item.title)}
            </div>
          </div>
        </Link>
      )}
    </Fragment>
  ));
};

export const ReportNavTab = ({ location }) => {
  return reportNavigationTab.map((item, index) => (
    <Link
      to={item.path}
      key={index}
      className={`relative before:content-[''] before:absolute before:bottom-1 before:left-0 before:h-1 before:w-full before:rounded-t-theme  transition-all duration-500 py-5 capitalize ${location.pathname === item.path ? "before:bg-primary-400" : ""
        }`}
    >
      <div className="flex items-center gap-2">
        {item.icon({ location, path: item.path })}
        <div
          className={`lg:text-base text-sm_18 ${location.pathname === item.path
            ? "text-info-400 font-bold"
            : "text-secondary-500"
            }`}
        >
          {item.title}
        </div>
      </div>
    </Link>
  ));
};
