import React, { Fragment, useState } from "react";
import Layout from "../../layout";
import CloseIcon from "../../assets/icon/close.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/logo/quetzal-logo.svg";
import { Button, Checkbox, IconButton, Input } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import OrderItemsTable from "../../components/payment/OrderItemsTable";
import {
  formatCurrencyWithNumberValue,
  getInitialsFirstCharAt,
} from "../../utils/utils";
import { useFormik } from "formik";
import { discountType, paymentTypes } from "../../utils/enums";
import { useDispatch, useSelector } from "react-redux";
import { createReturnOrderRequest } from "../../store/order/actions";
import ReturnOrderItemsTable from "../../components/payment/ReturnOrderItemsTable";
import moment from "moment";

const Receipt = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const order_id = useParams();
  const orderId = order_id?.id;

  const { isReturnOrderCreating } = useSelector((store) => ({
    isReturnOrderCreating: store?.order?.returnOrder?.loading,
  }));

  const dispatch = useDispatch();

  const location = useLocation();
  const formData = location.state?.FormData;

  const { returnOrderItems, returnOrder, customerData, alreadyPayableAmount } =
    formData;

  console.log(alreadyPayableAmount, "**** TOTAL PAID AMOUNT ****");

  console.log(customerData, "customerData");

  const [paymentDetail, setPaymentDetail] = useState([]);
  const [openPaymentSuccessModal, setOpenPaymentSuccessModal] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      returnOrder: {
        order_status: returnOrder?.order_status || "return",
        return_notes: returnOrder?.return_notes || "",
        customer_id: customerData?._id || "",
        total_qty: returnOrder?.total_qty || 0,
        sub_total: returnOrder?.sub_total || 0,
        total_discount: returnOrder?.total_discount || 0,
        total_taxes: returnOrder?.total_taxes || 0,
        grand_total: returnOrder?.grand_total || 0,
        redeemed_dollar: returnOrder?.redeemed_dollar || 0,
        redeemed_notes: returnOrder?.redeemed_notes || "",
        payable_amount: returnOrder?.payable_amount || 0,
      },
      returnOrderItems: returnOrderItems.map((item) => ({
        orderItemId: item.orderItemId || "",
        inventory_id: item.inventory_id || "",
        type: item.type || "",
        sku: item.sku || "",
        selling_price: item.selling_price || 0,
        qty: item.return_qty || 0,
        sub_total: item.sub_total || 0,
        discount_percentage: item.discount_percentage || 0,
        discount_type: item.discount_type || "percentage",
        discount: item.discount || 0,
        taxes: item.taxes || 0,
        grand_total: item.grand_total || 0,
      })),
      paymentInfo: formData?.paymentInfo || [],
    },
    onSubmit: (values, { resetForm }) => {
      console.log(values, "Submitted values");

      const createReturnData = {
        returnOrder: values.returnOrder,
        returnOrderItems: values.returnOrderItems,
        paymentInfo: values.paymentInfo,
      };

      console.log(createReturnData);

      dispatch(
        createReturnOrderRequest({
          orderId,
          body: createReturnData,
          resetForm,
          setOpenPaymentSuccessModal,
          navigate,
        })
      );
    },
  });

  console.log(formData); // The Formik data passed from the previous page

  // Calculate total payable amount with two decimal precision
  const totalPayableAmount = parseFloat(
    (formik.values.paymentInfo || [])
      .reduce((acc, t) => acc + (parseFloat(t.amount) || 0), 0)
      .toFixed(2)
  );

  console.log(totalPayableAmount, "totalPayableAmount");

  // total amount minus discounted show in table last column total
  const totalDiscountedAmount = formData.returnOrderItems?.reduce(
    (sum, item) => sum + (item.sub_total - item.discount),
    0
  );
  console.log(totalDiscountedAmount, "totalDiscountedAmount");

  const handleApplyValue = (type, idx) => {
    let updatedPaymentInfo = [...formik.values.paymentInfo];

    // Check if the payment type already exists in the paymentInfo array
    const existingIndex = updatedPaymentInfo.findIndex(
      (payment) => payment.payment_type === type
    );

    if (existingIndex !== -1) {
      // If it exists, update the existing entry
      updatedPaymentInfo[existingIndex].amount = Number(
        paymentDetail[idx].amount
      );
    } else {
      // If it doesn't exist, add a new entry
      updatedPaymentInfo.push({
        payment_type: type,
        amount: Number(paymentDetail[idx].amount),
      });
    }

    formik.setFieldValue("paymentInfo", updatedPaymentInfo); // Update Formik's paymentInfo
  };

  const handleChangeValue = (event, type) => {
    const tempArr = [...paymentDetail];
    const findIndex = tempArr.findIndex((item) => item.payment_type === type);
    if (event.target.checked) {
      if (findIndex === -1) {
        tempArr.push({
          payment_type: type,
          amount: "",
        });
      }
    } else {
      tempArr.splice(findIndex, 1);
      formik.setFieldValue("paymentInfo", tempArr);
    }
    setPaymentDetail(tempArr);
  };

  // cancle button svg code
  const handleRemovePaymentType = (index) => {
    const updatedPaymentInfo = formik.values.paymentInfo.filter(
      (item, ind) => ind !== index
    );
    formik.setFieldValue("paymentInfo", updatedPaymentInfo);
  };

  return (
    <Fragment>
      <form
        onSubmit={formik.handleSubmit}
        className="flex p-8 bg-white h-svh gap-x-6"
      >
        {/* <div className="p-8 flex gap-[14px] "> */}
        <div className="p-5 grow flex flex-col h-full bg-white shadow-[7px_5px_32.5px_0px_#856E6E0F] gap-y-4">
          <div>
            <img
              src={Logo}
              alt="logo"
              className="w-[135px] h-full flex-shrink-0"
            />
          </div>
          <div className="flex items-center gap-2">
            <IconButton
              type="button"
              variant="text"
              className=""
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} fontSize={24} />
            </IconButton>
            <p className="text-lg font-semibold capitalize">receipt info</p>
          </div>
          <div className="bg-primary-150 shadow-[7px_5px_32.5px_0px_#856E6E0F] p-5 ">
            <div className="flex items-center justify-between gap-2">
              <div className="flex ">
                <div className="flex items-center justify-center w-12 h-12 font-semibold text-white rounded-full bg-primary-400">
                  {getInitialsFirstCharAt(
                    customerData?.first_name || "",
                    customerData?.last_name || ""
                  )}
                </div>
                <h4 className="mt-3 text-lg font-medium text-info-400 ms-3 ">
                  {customerData?.first_name + " " + customerData?.last_name}
                </h4>
              </div>
              <div className="space-y-1 font-medium text-end">
                <p className="text-sm text-info-400">
                  {moment().format("ddd, MMMM D, YYYY")}
                </p>
                <p className="text-sm text-info-400">
                  {moment().format("HH:MM:A")}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-grow overflow-y-auto rounded-theme bg-secondary-100">
            <ReturnOrderItemsTable
              returnOrderItem={formData.returnOrderItems}
              returnOrder={formData.returnOrder}
              totalDiscountedAmount={Number(totalDiscountedAmount).toFixed(2)}
            />
          </div>
          <div className="flex flex-col p-5 mt-4 rounded-theme bg-secondary-100 gap-y-3">
            <div className="flex items-center justify-between">
              <p className="font-bold text-[14px] text-info-100">
                {t("Payment Activity")}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <p className="font-medium text-info-100">{t("Sub Total:")}</p>
              <p className="font-medium text-info-100">
                {formatCurrencyWithNumberValue(
                  Number(totalDiscountedAmount).toFixed(2)
                )}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-medium text-info-100">{t("Taxes") + ":"}</p>
              <p className="font-medium text-info-100">
                {formatCurrencyWithNumberValue(
                  formData.returnOrder?.total_taxes
                )}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-medium text-info-100">
                {t("Loyalty Points:")}
              </p>
              <p className="font-medium text-info-100">
                {formatCurrencyWithNumberValue(
                  formData.returnOrder?.redeemed_dollar
                )}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-medium text-info-100">
                {t("Returned amount:")}
              </p>
              <p className="font-medium text-info-100">
                {formatCurrencyWithNumberValue(alreadyPayableAmount || 0)}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <p className="font-semibold text-info-400">
                {t("Remaining Amount To Return:")}
              </p>
              <p className="font-semibold text-info-400">
                {formatCurrencyWithNumberValue(
                  formData.returnOrder.payable_amount -
                  Number(alreadyPayableAmount || 0)
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[560px] flex flex-col justify-between bg-secondary-100 px-5 py-4">
          <div>
            <div className="flex items-center justify-between">
              <h4 className="font-semibold capitalize text-info-400">
                {t("Choose your payment option")}
              </h4>
            </div>

            <div className="flex flex-col py-4 gap-y-4">
              <div className="bg-white p-0.5 rounded-theme border border-secondary-200">
                <label
                  htmlFor="cash"
                  className="flex items-center justify-between"
                >
                  <Checkbox
                    label="Cash"
                    ripple={false}
                    name="payment_type"
                    className="hover:before:opacity-0"
                    labelProps={{
                      className: "text-sm text-info-400 capitalize font-medium",
                    }}
                    // checked={paymentDetail.some(
                    //   (payment) => payment.payment_type === paymentTypes.CASH
                    // )}
                    onChange={(event) =>
                      handleChangeValue(event, paymentTypes.CASH)
                    }
                  />
                  {/* <div className="flex items-center justify-center px-3">
                    <MoneySvg />
                  </div> */}
                </label>
                {paymentDetail?.length > 0 &&
                  paymentDetail?.map(
                    (item, ind) =>
                      item.payment_type === paymentTypes.CASH && (
                        <div
                          className="flex items-center justify-between px-3 pb-2 gap-x-4"
                          key={ind}
                        >
                          <Input
                            type={"number"}
                            placeholder={`${formatCurrencyWithNumberValue(0)}`}
                            className={
                              "placeholder:font-normal bg-secondary-50"
                            }
                            value={item.amount} // Assuming you want to bind the input to `item.amount`
                            onChange={(e) => {
                              const tempArr = [...paymentDetail];
                              tempArr[ind].amount = e.target.value;
                              setPaymentDetail(tempArr);
                            }}
                          />
                          <Button
                            type="button"
                            className="flex-shrink-0 text-sm font-medium capitalize bg-primary-400 rounded-theme"
                            onClick={() =>
                              handleApplyValue(paymentTypes.CASH, ind)
                            }
                          >
                            {t("Apply")}
                          </Button>
                        </div>
                      )
                  )}
              </div>

              {customerData?.first_name !== null && customerData?.first_name !== "guest" &&
                <div className="bg-white p-0.5 rounded-theme border border-secondary-200">
                  <label
                    htmlFor="Store Credit"
                    className="flex items-center justify-between"
                  >
                    <Checkbox
                      label="Store Credit"
                      ripple={false}
                      name="payment_type"
                      className="hover:before:opacity-0"
                      labelProps={{
                        className: "text-sm text-info-400 capitalize font-medium",
                      }}
                      // checked={paymentDetail.some(
                      //   (payment) => payment.payment_type === paymentTypes.CASH
                      // )}
                      onChange={(event) =>
                        handleChangeValue(event, paymentTypes.STORE_CREDIT)
                      }
                    />
                    {/* <div className="flex items-center justify-center px-3">
                    <MoneySvg />
                  </div> */}
                  </label>
                  {paymentDetail?.length > 0 &&
                    paymentDetail?.map(
                      (item, ind) =>
                        item.payment_type === paymentTypes.STORE_CREDIT && (
                          <div
                            className="flex items-center justify-between px-3 pb-2 gap-x-4"
                            key={ind}
                          >
                            <Input
                              type={"number"}
                              placeholder={`${formatCurrencyWithNumberValue(0)}`}
                              className={
                                "placeholder:font-normal bg-secondary-50"
                              }
                              value={item.amount} // Assuming you want to bind the input to `item.amount`
                              onChange={(e) => {
                                const tempArr = [...paymentDetail];
                                tempArr[ind].amount = e.target.value;
                                setPaymentDetail(tempArr);
                              }}
                            />
                            <Button
                              type="button"
                              className="flex-shrink-0 text-sm font-medium capitalize bg-primary-400 rounded-theme"
                              onClick={() =>
                                handleApplyValue(paymentTypes.STORE_CREDIT, ind)
                              }
                            >
                              {t("Apply")}
                            </Button>
                          </div>
                        )
                    )}
                </div>}

            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            {formik?.values?.paymentInfo?.length > 0 && (
              <div className="flex flex-col p-3 bg-white border gap-y-3 rounded-theme border-secondary-200">
                <h4 className="text-sm font-bold text-info-400">
                  {t("Payment Type")}
                </h4>
                {returnOrder.payable_amount - (alreadyPayableAmount || 0) !==
                  totalPayableAmount && (
                    <div className="flex items-center justify-between">
                      <p>{t("Remaining Amount")}</p>
                      <p>
                        {formatCurrencyWithNumberValue(
                          returnOrder.payable_amount -
                          (alreadyPayableAmount || 0) -
                          totalPayableAmount
                        )}
                      </p>
                    </div>
                  )}

                {formik?.values?.paymentInfo?.map((item, ind) => (
                  <div className="flex items-center justify-between" key={ind}>
                    <p className="font-medium capitalize text-info-100">
                      {item?.payment_type}
                    </p>
                    <div className="flex items-center gap-3">
                      <p className="font-medium text-info-100">
                        {formatCurrencyWithNumberValue(item?.amount)}
                      </p>
                      <svg
                        onClick={() => handleRemovePaymentType(ind)}
                        width="12"
                        height="12"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                      >
                        <path
                          d="M1 15L8 8M8 8L15 1M8 8L15 15M8 8L1 1"
                          stroke="#0F1A2A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {console.log(typeof returnOrder.payable_amount)}
            {console.log(typeof alreadyPayableAmount)}
            {console.log(typeof totalPayableAmount)}

            {console.log(isReturnOrderCreating, "isReturnOrderCreating")}
            {console.log(
              returnOrder.payable_amount,
              Number(alreadyPayableAmount),
              totalPayableAmount,
              "total amount"
            )}

            {console.log(
              returnOrder.payable_amount - (alreadyPayableAmount || 0) !==
              totalPayableAmount,
              "calculation check"
            )}

            <Button
              type="submit"
              className="flex items-center justify-center text-base font-medium capitalize bg-primary-400 rounded-theme gap-x-4"
              disabled={
                isReturnOrderCreating ||
                parseFloat(
                  (
                    returnOrder.payable_amount - (alreadyPayableAmount || 0)
                  ).toFixed(2)
                ) !== totalPayableAmount
              }
              loading={isReturnOrderCreating}
            >
              Pay {formatCurrencyWithNumberValue(totalPayableAmount)}
            </Button>
          </div>
        </div>
        {/* </div> */}
      </form>
    </Fragment>
  );
};

export default Receipt;
