import React, { useEffect, useLayoutEffect, useState } from "react";
import CalendarIcon from "../../assets/icon/calendar.svg";
import FilterDarkGray from "../../assets/icon/filter-dark-grey.svg";
import { Link } from "react-router-dom";
import ReportWrapper from "../../layout/ReportWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  getDailySummaryRequest,
  getDeadStockRequest,
  getExportedData,
  getGiftCardUsageRequest,
  getInventoryValuationRequest,
  getReturnAndRefundRequest,
} from "../../store/report/actions";
import Loader from "../common/Loader";
import ReactSelect from "../../components/common/ReactSelect";
import {
  filterOptions,
  formatCurrencyWithNumberValue,
  paymentTypeReportOptions,
  paymentTypesReport,
} from "../../utils/utils";

import {
  Button,
  Chip,
  Input,
  Popover,
  PopoverContent,
  PopoverHandler,
  Radio,
} from "@material-tailwind/react";
import { t } from "i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowRightLong,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx"; // Import XLSX for Excel export
import { ExportIcon, ShortingIcon } from "../svgs";
import DataTable from "../common/DataTable";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DailySummary = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  // get staleInventory summary details
  const {
    isLoading,
    totalPages,
    orderSummary,
    paymentSummary,
    giftCardSummary,
    returnOrderSummary,
  } = useSelector((store) => ({
    isLoading: store?.report?.DailySummary?.loading,
    orderSummary: store?.report?.DailySummary?.data?.orderSummary,
    paymentSummary: store?.report?.DailySummary?.data?.paymentSummary,
    giftCardSummary: store?.report?.DailySummary?.data?.giftCardSummary,
    returnOrderSummary: store?.report?.DailySummary?.data?.returnOrderSummary,
  }));
  const filterOptions = [
    { label: `${t("This Year")}`, value: "this_year" },
    { label: `${t("Last Week")}`, value: "last_week" },
    { label: `${t("Last Month")}`, value: "last_month" },
    { label: `${t("Last 6 Months")}`, value: "last_6_months" },
    { label: `${t("Last Year")}`, value: "last_year" },
  ];
  const dispatch = useDispatch();

  const [selectedDateType, setSelectedDateType] = useState("today");
  const [startDate, setStartDate] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [endDate, setEndDate] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);

  // pass filter data in API when press filter button then call
  const applyFilter = () => {
    console.log("apply");
    dispatch(
      getDailySummaryRequest({
        page: 1,
        limit,
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
      })
    );
    setOpenPopover(false);
  };

  // Function to handle Export
  const handleExport = async () => {
    const totalLimit = totalPages * limit || limit; // Calculate the total limit for the export

    await dispatch(
      getExportedData({
        path: "/reports/sales-summary",
        page: page,
        limit: totalLimit,
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
        category: selectedCategory,
      })
    ).then((response) => {
      const data = response?.data?.data || {};

      // Destructure the necessary fields
      const {
        orderSummary,
        paymentSummary,
        giftCardSummary,
        returnOrderSummary,
      } = data;

      // Structure for the Excel export, with titles and tables
      const exportData = [
        // Order Summary section
        ["orderSummary"], // Title
        [
          "totalSales",
          "totalTransaction",
          "totalAverageSales",
          "totalLoyaltyDollars",
          "totalDiscount",
        ], // Headers
        [
          orderSummary.totalSales || 0,
          orderSummary.totalTransaction || 0,
          orderSummary.totalAverageSales || 0,
          orderSummary.totalLoyaltyDollars || 0,
          orderSummary.totalDiscount || 0,
        ], // Values

        [], // Empty row for spacing

        // Payment Summary section
        ["paymentSummary"], // Title
        ["payment_type", "amount"], // Headers
        ...paymentSummary.map((payment) => [
          payment.payment_type || "",
          payment.amount || 0,
        ]), // Values

        [], // Empty row for spacing

        // Gift Card Summary section
        ["giftCardSummary"], // Title
        ["totalGiftCardSold", "totalGiftCardValue"], // Headers
        [
          giftCardSummary.totalGiftCardSold || 0,
          giftCardSummary.totalGiftCardValue || 0,
        ], // Values

        [], // Empty row for spacing

        // Return Order Summary section
        ["returnOrderSummary"], // Title
        ["totalReturns", "totalReturnAmount"], // Headers
        [
          returnOrderSummary.totalReturns || 0,
          returnOrderSummary.totalReturnAmount || 0,
        ], // Values
      ];

      // Convert the array into an Excel worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "DailySummary");

      // Export the Excel file
      XLSX.writeFile(workbook, "DailySummary.xlsx");
    });
  };

  // dispatch get stale inventory summary list request
  useLayoutEffect(() => {
    dispatch(
      getDailySummaryRequest({
        page: 1,
        limit,
        dateType: selectedDateType?.value || selectedDateType,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
      })
    );
  }, [dispatch, limit, page]);

  return (
    <ReportWrapper>
      <div className="grow overflow-y-auto xl:h-[calc(100vh-132px)] h-[calc(100vh-106px)]">
        <div className="flex items-center justify-between p-3 mb-4 bg-white rounded-theme xl:py-5 lg:px-5 lg:py-4">
          <div className="text-base font-semibold lg:text-lg 2xl:font-bold text-info-500 2xl:text-xl">
            {t("Daily Sales Summary")}
          </div>
          <div className="flex items-center ">
            {/* <div className="gap-2.5  xl:py-[14px] lg:py-2.5 py-1.5 px-[18px] ">
              <div>
                <ReactSelect
                  className="border-none min-w-[115px] w-full"
                  labelProps={"!text-xs font-semibold capitalize"}
                  options={paymentTypeReportOptions}
                  value={selectedPaymentType}
                  onChange={(data) => setSelectedPaymentType(data)}
                />
              </div>
            </div> */}
            <div className="flex items-center gap-3">
              <div>
                <Popover
                  placement="bottom-end"
                  open={openPopover}
                  handler={setOpenPopover}
                >
                  <PopoverHandler>
                    <Button className="flex items-center gap-3 text-sm font-semibold capitalize bg-white border border-gray-500 text-secondary-400">
                      <ShortingIcon />
                      {t("Filter")}
                      <FontAwesomeIcon
                        icon={openPopover ? faAngleUp : faAngleDown}
                      />
                    </Button>
                  </PopoverHandler>
                  <PopoverContent className="z-10 lg:w-[900px] w-[calc(100%-16px)] space-y-4">
                    <div className="flex items-center justify-between">
                      <p className="text-lg font-semibold capitalize text-info-500">
                        {t("Filter Options")}
                      </p>
                      <FontAwesomeIcon
                        onClick={() => setOpenPopover(false)}
                        icon={faClose}
                        className="w-5 h-5 text-info-500"
                      />
                    </div>
                    <div className="p-5 border border-secondary-200 rounded-theme">
                      <p className="text-lg font-medium capitalize text-secondary-400">
                        {t("Date Range")}
                      </p>
                      <div className="flex items-center gap-2 pt-4">
                        {[
                          "custom",
                          "today",
                          "yesterday",
                          "this_week",
                          "this_month",
                        ].map((type) => (
                          <p
                            key={type}
                            onClick={() => setSelectedDateType(type)}
                            className={`border ${selectedDateType === type
                              ? "border-primary-400 bg-primary-150 text-primary-400"
                              : "border-secondary-200 text-secondary-400"
                              } flex items-center justify-center xl:text-base text-sm_18 font-medium  rounded-3xl xl:px-[30px] lg:px-5 px-3.5 xl:py-3 py-2 cursor-pointer transition-all duration-300 text-nowrap capitalize`}
                          >
                            {t(type.split("_").join(" "))}
                          </p>
                        ))}
                        <ReactSelect
                          labelProps={"!text-xs font-semibold capitalize "}
                          options={filterOptions}
                          value={selectedDateType}
                          onChange={(data) => setSelectedDateType(data)}
                          placeholder={language === "fr" ? "plus" : "More"}
                        />
                      </div>
                      {selectedDateType === "custom" && (
                        <div className="flex items-center gap-5 pt-6">
                          <div>
                            <Input
                              label={t("Start Date")}
                              type={"date"}
                              value={startDate}
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(e) => setStartDate(e.target.value)}
                              className={
                                "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                              }
                            />
                          </div>
                          <div className="">
                            <FontAwesomeIcon
                              icon={faArrowRightLong}
                              className="w-5 h-5 "
                            />
                          </div>
                          <div>
                            <Input
                              label={t("End Date")}
                              type={"date"}
                              value={endDate}
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(e) => setEndDate(e.target.value)}
                              className={
                                "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <Button
                      type="submit"
                      fullWidth
                      onClick={applyFilter}
                      className="bg-primary-400 capitalize font-medium text-base w-[143px] h-[46px] "
                    >
                      {t("Filter")}
                    </Button>
                  </PopoverContent>
                </Popover>
              </div>
              <Button
                onClick={handleExport}
                className="flex items-center gap-3 text-sm font-semibold capitalize border bg-primary-150 text-primary-400 border-primary-400"
              >
                <ExportIcon />
                {/* <FontAwesomeIcon icon={faFolder} className="w-5 h-5" /> */}
                {t("Export")}
              </Button>
            </div>
          </div>
        </div>

        {/* <div className="gap-2.5">
          <div className="bg-white rounded-theme">
            <div className="h-full payment-table-container">
              <table className="w-full h-full table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left capitalize ">
                      Product Name
                    </th>
                    <th className="px-4 py-2 text-left capitalize ">Variant</th>
                    <th className="px-4 py-2 text-left capitalize ">
                      Cost Price
                    </th>
                    <th className="px-4 py-2 text-left capitalize">
                      Selling price
                    </th>
                    <th className="px-4 py-2 text-left capitalize">Quantity</th>
                    <th className="px-4 py-2 text-left capitalize">
                      Total Cost
                    </th>
                    <th className="px-4 py-2 text-left capitalize">
                      Total Retail
                    </th>
                    <th className="px-4 py-2 text-left capitalize">
                      Sold Items
                    </th>
                  </tr>
                </thead>

                {!isLoading && (
                  <>
                    <tbody className="divide-y divide-gray-200">
                      {inventoryValuation?.orders?.map((item) => {
                        return (
                          <tr>
                            <td className="px-4 py-3">
                              {item.product_name || "-"}
                            </td>
                            <td className="px-4 py-3">{item.variant || "-"}</td>
                            <td className="px-4 py-3">
                              {item.cost_price || "-"}
                            </td>
                            <td className="px-4 py-3">
                              {item.selling_price || "-"}
                            </td>
                            <td className="px-4 py-3">
                              {item.quantities || "-"}
                            </td>
                            <td className="px-4 py-3">
                              {item.totalCostOfInventory || "-"}
                            </td>
                            <td className="px-4 py-3">
                              {item.totalRetailValue || "-"}
                            </td>
                            <td className="px-4 py-3">
                              {item.itemSold || "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                )}
              </table>
            </div>
          </div>
        </div> */}

        <div className="flex justify-between w-full mb-4 gap-x-6 rounded-theme">
          <div className="w-full p-4 space-y-2 text-base bg-white 2xl:space-y-5 rounded-theme">
            <p className="font-semibold  2xl:text-[18px] text-base text-[#1E2A3B]">
              {t("Order Summary")}
            </p>
            <div className="grid w-full grid-cols-3 gap-3 lg:grid-cols-5">
              <div className="bg-[#F8F8F8] px-[10px] py-[6px] flex flex-col 2xl:gap-y-4 gap-y-2 rounded-theme">
                <p className="flex-grow 2xl:text-base_24 text-sm_18">
                  {t("Total Transactions")}
                </p>
                <p className="font-medium 2xl:text-xl/7 text-base/5 text-primary-400">
                  {orderSummary?.totalTransaction || 0}
                </p>
              </div>
              <div className="bg-[#F8F8F8] px-[10px] py-[6px] flex flex-col 2xl:gap-y-4 gap-y-2 rounded-theme">
                <p className="flex-grow 2xl:text-base_24 text-sm_18">
                  {t("Total Sales")}
                </p>
                <p className="font-medium 2xl:text-xl/7 text-base/5 text-primary-400">
                  {formatCurrencyWithNumberValue(orderSummary?.totalSales || 0)}
                </p>
              </div>
              <div className="bg-[#F8F8F8] px-[10px] py-[6px] flex flex-col 2xl:gap-y-4 gap-y-2 rounded-theme">
                <p className="flex-grow 2xl:text-base_24 text-sm_18">
                  {t("Total Loyalty")}
                </p>
                <p className="font-medium 2xl:text-xl/7 text-base/5 text-primary-400">
                  {formatCurrencyWithNumberValue(
                    orderSummary?.totalLoyaltyDollars || 0
                  )}
                </p>
              </div>

              <div className="bg-[#F8F8F8] px-[10px] py-[6px] flex flex-col 2xl:gap-y-4 gap-y-2 rounded-theme">
                <p className="flex-grow 2xl:text-base_24 text-sm_18">
                  {t("Total Discounts")}
                </p>
                <p className="font-medium 2xl:text-xl/7 text-base/5 text-primary-400">
                  {formatCurrencyWithNumberValue(
                    orderSummary?.totalDiscount || 0
                  )}
                </p>
              </div>

              <div className="bg-[#F8F8F8] px-[10px] py-[6px] flex flex-col 2xl:gap-y-4 gap-y-2 rounded-theme">
                <p className="flex-grow 2xl:text-base_24 text-sm_18 ">
                  {t("Total Average Sales ")}
                </p>
                <p className="font-medium 2xl:text-xl/7 text-base/5 text-primary-400">
                  {formatCurrencyWithNumberValue(
                    orderSummary?.totalAverageSales || 0
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid lg:grid-cols-11 gap-x-4">
          <div className="w-full lg:col-span-7">
            <div className="flex items-center justify-between w-full mb-3 gap-x-6 rounded-theme">
              <div className="w-full p-4 space-y-3 text-base bg-white rounded-theme ">
                <p className="font-semibold  2xl:text-[18px] text-base text-[#1E2A3B]">
                  {t("Gift Card Summary")}
                </p>
                <div className="flex justify-between w-full gap-x-3">
                  <div className="bg-[#F8F8F8] px-[10px] py-[6px] flex flex-col gap-y-4 rounded-theme w-full">
                    <p className="flex-grow 2xl:text-base_24 text-sm_18">
                      {t("Gift Cards Sold")}
                    </p>
                    <p className="font-medium 2xl:text-xl/7 text-base/5 text-primary-400">
                      {giftCardSummary?.totalGiftCardSold || 0}
                    </p>
                  </div>
                  <div className="bg-[#F8F8F8] px-[10px] py-[6px] flex flex-col gap-y-4 rounded-theme w-full">
                    <p className="flex-grow 2xl:text-base_24 text-sm_18">
                      {t("Total Gift Card Sales Value")}
                    </p>
                    <p className="font-medium 2xl:text-xl/7 text-base/5 text-primary-400">
                      {formatCurrencyWithNumberValue(
                        giftCardSummary?.totalGiftCardValue || 0
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between w-full col-span-4 mb-3 gap-x-6 rounded-theme">
              <div className="w-full p-4 space-y-3 text-base bg-white rounded-theme ">
                <p className="font-semibold  2xl:text-[18px] text-base text-[#1E2A3B]">
                  {t("Return Order Summary")}
                </p>
                <div className="flex justify-between w-full gap-x-3">
                  <div className="bg-[#F8F8F8] px-[10px] py-[6px] flex flex-col gap-y-4 rounded-theme w-full">
                    <p className="flex-grow 2xl:text-base_24 text-sm_18">
                      {t("Total Returns")}
                    </p>

                    <p className="font-medium 2xl:text-xl/7 text-base/5 text-primary-400">
                      {returnOrderSummary?.totalReturns || 0}
                    </p>
                  </div>
                  <div className="bg-[#F8F8F8] px-[10px] py-[6px] flex flex-col gap-y-4 rounded-theme w-full">
                    <p className="flex-grow 2xl:text-base_24 text-sm_18">
                      {t("Total Return Amount")}
                    </p>
                    <p className="font-medium 2xl:text-xl/7 text-base/5 text-primary-400">
                      {formatCurrencyWithNumberValue(
                        returnOrderSummary?.totalReturnAmount || 0
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full mb-4 lg:col-span-4 gap-x-6 rounded-theme ">
            <div className="w-full p-4 2xl:space-y-6 space-y-4 text-base bg-white rounded-theme h-[280px]">
              <p className="font-semibold  2xl:text-[18px] text-base text-[#1E2A3B]">
                {t("Payment Summary")}
              </p>
              {!isLoading ? (
                paymentSummary?.map((items, index) => {
                  return (
                    <div key={index} className="flex justify-between ">
                      <p className="capitalize 2xl:text-base_24 text-sm_18">
                        {paymentTypesReport(t)?.find(
                          (type) => type?.label === items?.payment_type
                        )?.value || items?.payment_type}
                      </p>
                      <p className="2xl:text-[18px] text-sm_18 text-primary-400 font-medium ">
                        {formatCurrencyWithNumberValue(items?.amount || 0)}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className="flex items-center justify-center pt-12">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ReportWrapper>
  );
};

export default DailySummary;
