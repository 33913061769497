import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton } from "@material-tailwind/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactSelect from "../common/ReactSelect";
import Input from "../common/Input";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserOrgLocationSetting } from "../../utils/utils";

const ManageVariant = ({
  openVariantModal,
  handler,
  allAttributes,
  handleAddVariantValue,
  addedVariantsList,
}) => {
  const [attributeValue, setAttributeValue] = useState([]);
  const [sellingPrice, setSellingPrice] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [stock, setStock] = useState("");
  const [sku, setSku] = useState("");
  const { t } = useTranslation();

  const orgDetail = getUserOrgLocationSetting();

  const currencySymbol = orgDetail?.currency?.symbol;

  // Function to check if all required fields are filled
  const areAllFieldsFilled = () => {
    return (
      attributeValue.length > 0 &&
      sellingPrice.trim() !== "" &&
      costPrice.trim() !== "" &&
      stock.trim() !== ""
    );
  };

  const handleChangeSelectValue = (data, index) => {
    const newArr = [...attributeValue];
    newArr[index] = data; // Set the new value at the correct index
    setAttributeValue(newArr);
  };

  //   const handleChangeSelectValue = (data, index) => {
  //     console.log({ data, index }, "data");
  //     console.log(attributeValue, "attributeValue");
  //     const findIndex = attributeValue.findIndex((_, ind) => ind === index);
  //     if (findIndex !== -1) {
  //       const newArr = [...attributeValue];
  //       newArr[findIndex] = data;
  //       setAttributeValue(newArr);
  //     } else {
  //       attributeValue.push(data);
  //       setAttributeValue(attributeValue);
  //     }
  //   };

  const handleAddVariant = () => {
    const alreadyAddedVariants = [...addedVariantsList];
    const mergedValue = attributeValue.map((item) => item.value).join("/");
    if (
      alreadyAddedVariants.some((attribute) => attribute.name === mergedValue)
    ) {
      toast.error("Duplicate variant name is not allowed!");
      return;
    }
    const data = {
      name: mergedValue,
      price: sellingPrice,
      cost_price: costPrice,
      stock,
    };
    handleAddVariantValue(data);
    setAttributeValue([]);
    setCostPrice("");
    setSellingPrice("");
    setStock("");
    setSku("");
    handler();
  };

  useEffect(() => {
    if (!openVariantModal) {
      setAttributeValue([]);
      setCostPrice("");
      setSellingPrice("");
      setStock("");
      setSku("");
    }
  }, [openVariantModal]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between px-5 py-3">
        <h3 className="text-lg font-semibold capitalize text-info-400">
          {t("Add Variant")}
        </h3>
        <IconButton
          type="button"
          size="sm"
          variant="text"
          className=""
          onClick={handler}
        >
          <FontAwesomeIcon icon={faClose} fontSize={18} />
        </IconButton>
      </div>
      <div className="flex flex-col flex-1 px-5 py-3 bg-secondary-100 gap-y-4">
        {allAttributes.map(
          (item, index) => (
            console.log(attributeValue[index], "attributeValue[index]"),
            (
              <div key={index}>
                <ReactSelect
                  label={item.attribute_name}
                  labelProps={"!text-base font-semibold capitalize"}
                  options={item.options.map((opt) => ({
                    label: opt.val,
                    value: opt.val,
                  }))}
                  placeholder={`Select ${item.attribute_name}`}
                  value={attributeValue[index] ? attributeValue[index] : ""}
                  onChange={(data) => handleChangeSelectValue(data, index)}
                />
              </div>
            )
          )
        )}
      </div>
      <div className="px-5 py-3 grid grid-cols-4 gap-x-3.5">
        <Input
          type={"number"}
          label={t("Selling Price")}
          labelProps={"text-info-100 font-medium !text-base"}
          value={sellingPrice}
          onChange={(event) => setSellingPrice(event.target.value)}
          iconStart={<p className="font-semibold">{currencySymbol}</p>}
          placeholder={"00"}
        />
        <Input
          type={"number"}
          label={t("Cost Price")}
          labelProps={"text-info-100 font-medium !text-base"}
          value={costPrice}
          onChange={(event) => setCostPrice(event.target.value)}
          iconStart={<p className="font-semibold">{currencySymbol}</p>}
          placeholder={"00"}
        />
        <Input
          type={"number"}
          label={t("Stock")}
          labelProps={"text-info-100 font-medium !text-base"}
          value={stock}
          onChange={(event) => setStock(event.target.value)}
          placeholder={"00"}
        />
        <div>
          <div className="opacity-0">1213</div>
          <Button
            type="button"
            className="flex items-center justify-center text-base font-semibold capitalize bg-primary-400 gap-x-2"
            fullWidth
            onClick={handleAddVariant}
            disabled={!areAllFieldsFilled()}
          >
            <FontAwesomeIcon icon={faPlus} fontSize={16} />
            {t("Add")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ManageVariant;

// import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Button, IconButton } from "@material-tailwind/react";
// import React, { useEffect, useState } from "react";
// import ReactSelect from "../common/ReactSelect";
// import Input from "../common/Input";
// import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";

// const ManageVariant = ({
//   openVariantModal,
//   handler,
//   allAttributes,
//   handleAddVariantValue,
//   addedVariantsList,
// }) => {
//   const [attributeValue, setAttributeValue] = useState([]);
//   const [sellingPrice, setSellingPrice] = useState("");
//   const [costPrice, setCostPrice] = useState("");
//   const [stock, setStock] = useState("");
//   const [sku, setSku] = useState("");
//   const { t } = useTranslation();

//   // Function to check if all required fields are filled
//   const areAllFieldsFilled = () => {
//     return (
//       attributeValue.length > 0 &&
//       sellingPrice.trim() !== "" &&
//       costPrice.trim() !== "" &&
//       stock.trim() !== ""
//     );
//   };

//   const handleChangeSelectValue = (data, index) => {
//     const newArr = [...attributeValue];
//     newArr[index] = data; // Set the new value at the correct index
//     setAttributeValue(newArr);
//   };

//   const handleAddVariant = () => {
//     const alreadyAddedVariants = [...addedVariantsList];
//     const mergedValue = attributeValue.map((item) => item.value).join("/");
//     if (
//       alreadyAddedVariants.some((attribute) => attribute.name === mergedValue)
//     ) {
//       toast.error("Duplicate variant name is not allowed!");
//       return;
//     }
//     const data = {
//       name: mergedValue,
//       price: sellingPrice,
//       cost_price: costPrice,
//       stock,
//     };
//     handleAddVariantValue(data);
//     resetForm();
//     handler();
//   };

//   const resetForm = () => {
//     setAttributeValue([]);
//     setCostPrice("");
//     setSellingPrice("");
//     setStock("");
//     setSku("");
//   };

//   useEffect(() => {
//     if (!openVariantModal) {
//       resetForm(); // Reset the form when the modal is closed
//     }
//   }, [openVariantModal]);

//   return (
//     <div className="flex flex-col h-full">
//       <div className="flex items-center justify-between px-5 py-3">
//         <h3 className="text-lg font-semibold capitalize text-info-400">
//           {t("Add Variant")}
//         </h3>
//         <IconButton type="button" size="sm" variant="text" onClick={handler}>
//           <FontAwesomeIcon icon={faClose} fontSize={18} />
//         </IconButton>
//       </div>
//       <div className="flex flex-col flex-1 px-5 py-3 bg-secondary-100 gap-y-4">
//         {allAttributes.map((item, index) => (
//           <div key={index}>
//             <ReactSelect
//               label={item.attribute_name}
//               labelProps={"!text-base font-semibold capitalize"}
//               options={item.options.map((opt) => ({
//                 label: opt.val,
//                 value: opt.val,
//               }))}
//               placeholder={`Select ${item.attribute_name}`}
//               value={attributeValue[index] || null} // Set value to the selected item or null
//               onChange={(data) => handleChangeSelectValue(data, index)}
//             />
//           </div>
//         ))}
//       </div>
//       <div className="px-5 py-3 grid grid-cols-4 gap-x-3.5">
//         <Input
//           type={"number"}
//           label={t("Selling Price")}
//           labelProps={"text-info-100 font-medium !text-base"}
//           value={sellingPrice}
//           onChange={(event) => setSellingPrice(event.target.value)}
//           iconStart={<p className="font-semibold">$</p>}
//           placeholder={"00"}
//         />
//         <Input
//           type={"number"}
//           label={t("Cost Price")}
//           labelProps={"text-info-100 font-medium !text-base"}
//           value={costPrice}
//           onChange={(event) => setCostPrice(event.target.value)}
//           iconStart={<p className="font-semibold">$</p>}
//           placeholder={"00"}
//         />
//         <Input
//           type={"number"}
//           label={t("Stock")}
//           labelProps={"text-info-100 font-medium !text-base"}
//           value={stock}
//           onChange={(event) => setStock(event.target.value)}
//           placeholder={"00"}
//         />
//         <div>
//           <div className="opacity-0">1213</div>
//           <Button
//             type="button"
//             className="flex items-center justify-center text-base font-semibold capitalize bg-primary-400 gap-x-2"
//             fullWidth
//             onClick={handleAddVariant}
//             disabled={!areAllFieldsFilled()}
//           >
//             <FontAwesomeIcon icon={faPlus} fontSize={16} />
//             {t("Add")}
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ManageVariant;
