import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getListCashDrawerRequest } from "../../store/register/cashDrawer/action";
import { formatCurrencyWithNumberValue } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import moment from "moment";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import Loader from "../common/Loader";
import { getListCashDrawerRequest } from "../../rq-store/register/cash-drawer";
import { toast } from "react-toastify";
// import { getListCashDrawerRequest } from "../../actions/cashDrawer";

const CashDrawerList = ({ adjustments, setAdjustments }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [limit, setLimit] = useState(10);

  const [runningTotal, setRunningTotal] = useState(0);

  const { totalPages } = useSelector((store) => ({
    // isLoading: store?.cashDrawer?.cashDrawersList?.loading,
    // cashDrawerList: store?.cashDrawer?.cashDrawersList?.data?.cashDrawer,
    // netAmount: store?.cashDrawer?.cashDrawersList?.data?.netAmount,
    totalPages: store?.cashDrawer?.cashDrawersList?.data?.totalPages,
  }));

  // cashDrawerList api request
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ["cashDrawerList"],
    queryFn: ({ pageParam = 1 }) =>
      getListCashDrawerRequest({
        page: pageParam,
        limit,
      }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.cashDrawer?.length === 10
        ? allPages?.length + 1
        : undefined;
    },
    staleTime: 5 * 60 * 1000,
    retry: 1, // Set to retry only 1 time upon failure
    onError: (err) => {
      toast.error(err.message);
    },
  });

  console.log(data, "data............");
  console.log(adjustments, "adjustments............");
  const cashDrawerList =
    data?.pages?.flatMap((page) => page.cashDrawer || []) || [];

  const netAmount = data?.pages[0]?.netAmount;

  // const netAmount = data.pages;

  // total user wise Feed pages data
  // const totalFeedData = userWiseFeed?.pages?.flatMap((val) => val);

  const { ref, inView } = useInView();

  // fetch next page on scroll
  useEffect(() => {
    console.log(hasNextPage, "hasNextPage........");
    console.log(inView, "inView........");
    if (!isFetching && inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  console.log(cashDrawerList, "cashDrawerList..........");

  // const handleScroll = (event) => {
  //   const { scrollHeight, scrollTop, clientHeight } = event.target;
  //   let scrollOffset = scrollHeight - scrollTop - clientHeight;
  //   if (scrollOffset < 50 && totalPages > page && !isLoading) {
  //     setPage((prevPage) => prevPage + 1);
  //   }y
  // };

  // const handleScroll = debounce((event) => {
  //   const { scrollHeight, scrollTop, clientHeight } = event.target;
  //   let scrollOffset = scrollHeight - scrollTop - clientHeight;
  //   if (scrollOffset < 50 && totalPages > page && !isLoading) {
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // }, 300);

  // useEffect(() => {
  //   if (page === 1) {
  //     setCashDrawersData(cashDrawerList || []);
  //   } else if (cashDrawerList?.length > 0) {
  //     setCashDrawersData((prevCashDrawers) => [
  //       ...prevCashDrawers,
  //       ...cashDrawerList,
  //     ]);
  //   }
  // }, [cashDrawerList, page]);

  // useEffect(() => {
  //   dispatch(
  //     getListCashDrawerRequest({
  //       page,
  //       limit,
  //     })
  //   );
  // }, [dispatch, page, limit]);

  useEffect(() => {
    if (!cashDrawerList || cashDrawerList.length === 0) return;
    let total = netAmount;
    let adjustmentsArr = [];

    cashDrawerList?.forEach((item) => {
      let adjustment = 0;

      if (item?.drawer_action === "cash in amount") {
        total += item?.amount || 0;
        adjustment = item?.amount || 0;
      } else if (
        item?.drawer_action === "drawer count" ||
        item?.drawer_action === "count to open drawer"
      ) {
        // total -= item?.amount || 0;
        // adjustment = -(item?.amount || 0);
        adjustment = (item?.amount || 0) - total;
        total = item?.amount || 0;
      } else if (
        item?.drawer_action === "amount paid out" ||
        item?.drawer_action === "amount removed to close drawer"
      ) {
        total -= item?.amount || 0;
        adjustment = -(item?.amount || 0);
      }

      adjustmentsArr.push({
        action: item?.drawer_action,
        amount: item?.amount,
        action_time: item?.action_time,
        user_email: item?.user_email,
        adjustment,
        runningTotal: total,
      });
    });

    // Only update state if there are changes
    if (
      runningTotal !== total ||
      JSON.stringify(adjustments) !== JSON.stringify(adjustmentsArr)
    ) {
      setRunningTotal(total);
      setAdjustments(adjustmentsArr);
    }
  }, [cashDrawerList, netAmount]);

  return (
    <div>
      <div
        // onScroll={handleScroll}
        className="h-[calc(100vh-166px)] overflow-y-auto p-5"
      >
        <table className="flex-grow overflow-auto rounded-theme">
          <thead className="min-w-full text-start">
            <tr>
              <th className="capitalize text-start w-[350px] pr-5 py-5 ">
                {t("Details")}
              </th>
              <th className="capitalize text-start w-[300px] pr-5 py-5">
                {t("Action")}
              </th>
              <th className="capitalize text-start w-[250px] pr-5 py-5">
                {t("adjustments")}
              </th>
              <th className="capitalize text-start w-[250px]">
                {t("Running total")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t border-secondary-200">
              <td className="py-5 pr-5">{t("Start of Day Float")}</td>
              <td className="py-5 pr-5"></td>
              <td className="py-5 pr-5"></td>
              <td className="py-5 pr-5">
                {formatCurrencyWithNumberValue("0.00")}
              </td>
            </tr>
            <tr className="border-t border-secondary-200">
              <td className="py-5 pr-5">{t("Net Sales")}</td>
              <td className="py-5 pr-5"></td>
              <td className="py-5 pr-5">
                {formatCurrencyWithNumberValue("0.00")}
              </td>
              <td className="py-5 pr-5">
                {formatCurrencyWithNumberValue(
                  netAmount ? netAmount?.toFixed(2) : "0.00"
                )}
              </td>
            </tr>
            {/* <tr className="border-t border-secondary-200">
              <td className="py-5 pr-5">{t("Cashback")}</td>
              <td className="py-5 pr-5"></td>
              <td className="py-5 pr-5">
                {formatCurrencyWithNumberValue("0.00")}
              </td>
              <td className="py-5 pr-5">
                {formatCurrencyWithNumberValue("0.00")}
              </td>
            </tr> */}
            {adjustments.map(
              (item, index) => (
                console.log(item, "item............."),
                (
                  <tr key={index} className="border-t border-secondary-200">
                    {/* {console.log(item, "items")} */}
                    <td className="py-5 pr-5 ">
                      {item?.user_email + " " + "(" + item?.action_time + ")"}
                    </td>
                    <td className="py-5 pr-5 capitalize ">
                      {t(item?.action) +
                        " " +
                        "(" +
                        formatCurrencyWithNumberValue(item?.amount) +
                        ")"}
                    </td>
                    <td
                      className={`pr-5 py-5 ${item?.adjustment < 0 ? "text-red-500" : ""
                        }`}
                    >
                      {formatCurrencyWithNumberValue(
                        item?.adjustment ? `${item?.adjustment.toFixed(2)}` : ""
                      )}
                    </td>
                    <td className="py-5">
                      {formatCurrencyWithNumberValue(
                        `${item?.runningTotal.toFixed(2)}`
                      )}
                    </td>
                  </tr>
                )
              )
            )}
            {/* {cashDrawersData?.map((items, index) => {
              return (
                <tr className="">
                  <td className="py-3">
                    {items?.user_email + " " + "(" + items?.action_time + ")"}
                  </td>
                  <td>{items?.drawer_action}</td>
                  <td></td>
                  <td></td>
                </tr>
              );
            })} */}
            {/* Loader for next page */}
            {console.log(isLoading, "isLoading...............")}
            {console.log(isFetching, "isFetching...............")}
            <tr ref={ref}>
              <td
                colSpan={4}
                className={` py-5 text-center ${!hasNextPage ? "hidden" : ""}`}
              >
                {/* {(isLoading || isFetching) && <Loader />} */}
              </td>
            </tr>

            <tr>
              <td colSpan={4} className={` py-5 text-center `}>
                {(isLoading || isFetching) && <Loader />}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CashDrawerList;
