import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  orderList: {
    loading: false,
    data: [],
    error: null,
    totalPages: null,
  },
  orderDetail: {
    loading: false,
    data: null,
    error: null,
  },
  selectedProducts: [],
  addToCart: {
    loading: false,
    error: false,
    data: null,
  },
  createOrder: {
    loading: false,
    data: null,
    error: false,
  },
  returnOrder: {
    loading: false,
    data: null,
    error: false,
  },
  cartItems: {
    loading: false,
    data: null,
    error: false,
  },
  getGiftCard: {
    loading: false,
    data: null,
    error: false,
  },
  deleteHoldOrder: {
    loading: false,
    data: null,
    error: false,
  },
  addToLastTransaction: {
    loading: false,
    error: false,
    data: null,
  },
  lastTransactionDetail: {
    loading: false,
    error: false,
    data: null,
  },
  sendReceiptDetails: {
    loading: false,
    error: false,
    data: null,
  },
}

export const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    orderState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { orderState } = orderSlice.actions;
export default orderSlice.reducer;
