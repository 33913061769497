import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const Modal = ({
  open,
  handler,
  backdropClose,
  children,
  containerProps = "",
  size = "sm",
}) => {
  useEffect(() => {
    const scrollbarElement = document.body;

    // Hide scrollbar when modal is open
    if (open && scrollbarElement.style.overflow !== "hidden") {
      scrollbarElement.setAttribute("style", "overflow: hidden");
    }

    // Close modal on 'Esc' key press
    const handleEscKeyPress = (event) => {
      if (event.key === "Escape" && open) {
        handler(); // Close modal
      }
    };

    document.addEventListener("keydown", handleEscKeyPress);

    // Cleanup the event listener and restore the scrollbar
    return () => {
      document.removeEventListener("keydown", handleEscKeyPress);
      scrollbarElement.setAttribute("style", "overflow: auto");
    };
  }, [open, handler]);

  return ReactDOM.createPortal(
    <div
      className={`fixed inset-0 z-[9999] flex items-center justify-center bg-gray-800 bg-opacity-50 transition-opacity duration-300 ${open ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      onClick={() => {
        if (!backdropClose) {
          handler();
        }
      }}
    >
      <div
        className={`bg-white rounded-theme shadow-lg p-4 transform transition-transform duration-300 ${containerProps} ${open ? "scale-100" : "scale-90"
          }
        ${size === "xxs"
            ? "w-full md:w-2/5 lg:w-2/6 2xl:w-1/5 min-w-[80%] md:min-w-[40%] lg:min-w-[33.333333%] 2xl:min-w-[20%] max-w-[80%] md:max-w-[40%] lg:max-w-[33.333333%] 2xl:max-w-[20%]"
            : size === "xs"
              ? "w-full md:w-3/5 lg:w-2/5 2xl:w-1/4 min-w-[80%] md:min-w-[60%] lg:min-w-[40%] 2xl:min-w-[25%] max-w-[80%] md:max-w-[60%] lg:max-w-[40%] 2xl:max-w-[25%]"
              : size === "sm"
                ? "w-full md:w-2/3 lg:w-2/4 2xl:w-1/3 min-w-[80%] md:min-w-[66.666667%] lg:min-w-[50%] 2xl:min-w-[33.333333%] max-w-[80%] md:max-w-[66.666667%] lg:max-w-[50%] 2xl:max-w-[33.333333%]"
                : size === "md"
                  ? "w-full md:w-3/4 lg:w-3/5 2xl:w-2/5 min-w-[90%] md:min-w-[75%] lg:min-w-[60%] 2xl:min-w-[40%] max-w-[90%] md:max-w-[75%] lg:max-w-[60%] 2xl:max-w-[40%]"
                  : size === "lg"
                    ? "w-full md:w-5/6 lg:w-3/4 2xl:w-3/5 min-w-[90%] md:min-w-[83.333333%] lg:min-w-[75%] 2xl:min-w-[60%] max-w-[90%] md:max-w-[83.333333%] lg:max-w-[75%] 2xl:max-w-[60%"
                    : size === "xl"
                      ? "w-full md:w-5/6 2xl:w-3/4 min-w-[95%] md:min-w-[83.333333%] 2xl:min-w-[75%] max-w-[95%] md:max-w-[83.333333%] 2xl:max-w-[75%]"
                      : size === "xxl"
                        ? "flex flex-col w-screen min-w-[100vw] max-w-[100vw] h-screen min-h-[100vh] max-h-[100vh] m-0 rounded-none"
                        : ""
          }
        `}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the dialog
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
