import { toast } from "react-toastify";
import { orderState } from "./state";
import AxiosRequest from "../../AxiosRequest";
import AxiosIntegrations from "../../AxiosIntegrations";

/* -------------------------------------------------------------------------- */
/*                             get order list API                             */
/* -------------------------------------------------------------------------- */
export const getOrderListRequest =
  ({
    page,
    limit,
    search = "",
    status = "",
    dateType = "",
    body,
    start_date,
    end_date,
    resource,
  }) =>
    async (dispatch) => {
      console.log(body, "===== body =====");
      console.log(status, "===== status ======");
      try {

        const totalResource = resource?.map(data => `resource[]=${data}`).join("&");

        let url;
        if (dateType !== "custom") {
          url = `/user/order/list?page=${page}&limit=${limit}&search=${search}&status=${status}&dateType=${dateType}&${totalResource}`;
        } else {
          url = `/user/order/list?page=${page}&limit=${limit}&search=${search}&status=${status}&dateType=${dateType}&${totalResource}&start_date=${start_date}&end_date=${end_date}`;
        }
        console.log(body, "===== **body** =====");
        dispatch(orderState({ key: "orderList.loading", value: true }));
        const response = await AxiosRequest.get(
          url
        );
        console.log(response, "res from get order list request");
        const data = await response?.data?.data;
        if (data) {
          dispatch(orderState({ key: "orderList.data", value: data?.orders }));
          dispatch(
            orderState({
              key: "orderList.totalPages",
              value: data?.totalPages,
            })
          );
          dispatch(orderState({ key: "orderList.loading", value: false }));
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(orderState({ key: "orderList.loading", value: false }));
        dispatch(orderState({ key: "orderList.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                          get order detail request                          */
/* -------------------------------------------------------------------------- */
export const getOrderDetailRequest =
  ({ orderId }) =>
    async (dispatch) => {
      try {
        dispatch(orderState({ key: "orderDetail.loading", value: true }));
        const response = await AxiosRequest.get(`/user/order/details/${orderId}`);
        console.log(response, "res from get order details");
        const data = await response.data.data;
        if (data) {
          dispatch(orderState({ key: "orderDetail.data", value: data }));
          dispatch(orderState({ key: "orderDetail.loading", value: false }));
        }
        return response;
      } catch (error) {
        toast.error(error);
        dispatch(orderState({ key: "orderDetail.loading", value: false }));
        dispatch(orderState({ key: "orderDetail.error", value: error }));
      }
    };

/* -------------------------------------------------------------------------- */
/*                          send print request                                */
/* -------------------------------------------------------------------------- */
export const sendPrinterRequest = (printerData) => async (dispatch) => {
  try {

    const {
      org_location_id,
      lane_no,
      location_name,
      location_address,
      org_name,
      footer,
      customer,
      order,
      orderItems,
      payments,
      receipt_type
    } = printerData

    const printer_env = process.env.REACT_APP_ENV;

    const requestBody = {
      config: { org_location_id, printer_env, lane_no, receipt_type, location_name, location_address, org_name, footer },
      receipt_data: {
        customer,
        order,
        orderItems,
        payments,
      }
    }

    const response = await AxiosIntegrations.post('/cloudprnt/create', requestBody);
    const { status, msg = '' } = response.data;

    if (status === 200) {
      toast.success(msg);
    } else {
      toast.error(msg);
    }

  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.msg);
  }
};