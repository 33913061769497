import React from "react";
import Layout from "../../layout";
import Accounting from "./Accounting";
import CustomerTable from "./CustomerTable";
import TransactionDetails from "./TransactionDetails";
import TransactionHistory from "./TransactionHistory";
import AddToBuy from "./AddToBuy";
import { reportNavigationTab } from "../../routes/navigation";
import { Link, useLocation } from "react-router-dom";
import { t } from "i18next";

const Report = (selectedCategory) => {
  const location = useLocation();
  return (
    // <div>
    //   <Layout>
    //     {/* <Accounting /> */}
    //     {/* <CustomerTable /> */}
    //     <DailySummary />
    //     {/* <TransactionDetails /> */}
    //     {/* <TransactionHistory /> */}
    //     {/* <AddToBuy /> */}
    //   </Layout>
    // </div>
    <div className="flex-shrink-0 overflow-y-auto xl:h-[calc(100vh-116px)] h-[calc(100vh-90px)] bg-white rounded-theme w-auto 2xl:w-72">
      {reportNavigationTab.map((item, index) =>
        item.menuTitle ? (
          // If `menuTitle` is present, render it in a `<p>` tag
          <p
            key={index}
            className="px-5 py-[11.5px] text-base font-semibold 2xl:text-sm text-[#1E2A3B]"
          >
            {t(item.menuTitle)}
          </p>

        ) : (
          // Otherwise, render the item as a clickable navigation link
          <Link to={item.path} key={index}>
            <div
              className={`2xl:text-base_24  xl:py-2.5 py-2 lg:px-5 px-3  ml-4 text-sm_18  font-normal  ${location.pathname.includes(item.path)
                ? "bg-primary-150 text-primary-400 font-medium rounded-lg max-w-[254px] "
                : "hover:bg-[#f6f7f8]  rounded-lg max-w-[254px]"
                }   cursor-pointer transition duration-500 text-[#1E2A3B]`}
            >
              {t(item.title)}
            </div>
          </Link>
        )
      )}
    </div>
  );
};

export default Report;
